import { Dictionary } from 'src/app/providers/_dictionary/dictionary';
import { StaticDictValues } from 'src/app/providers/_dictionary/dictionary.const';
import { DictionaryOfDaData, DictionaryOf } from 'src/app/providers/_dictionary/dictionary.interface';
import { onv_code_list_brm, groro_code_list_brm, type_of_income_for_act_create_transform, eek_state_services_map, onv_request_type_map, onv_request_statuses_map, groro_statuses_map, waste_class_assignment_statuses_map, eek_petition_statuses_map, nsi_erknm_statuses_filter_map } from 'src/app/providers/_dictionary/dictionary.utils';
import { ICheckListQuestionsInstance, IErpInspection } from 'src/app/providers/_interfaces/check-list.interface';
import { NsiCitesKinds, NsiCitesSourcesTarget, NsiCitesDesignations, NsiCitesAttachments } from 'src/app/providers/_interfaces/cites/cites.interface';
import { ITemplateCategory, IExtendedProfile, IOkved, ICriteria, IControlCategory, IAggregateConditions, IRequestDocType } from 'src/app/providers/_interfaces/common';
import { ICountries } from 'src/app/providers/_interfaces/countries.interface';
import { DictionaryStorage, IDictionaryItem } from 'src/app/providers/_interfaces/dictionary';
import { IFKKODict } from 'src/app/providers/_interfaces/fkko.interface';
import { IGeeDocumentsTypes } from 'src/app/providers/_interfaces/gee/gee-documents-types.interface';
import { IOroPurposes, IOroKinds } from 'src/app/providers/_interfaces/groro.interface';
import { IHydronyms, IHydronymType, ISeas } from 'src/app/providers/_interfaces/hydronyms.interface';
import { IInstructionGroup } from 'src/app/providers/_interfaces/instructions.interface';
import { IKbk } from 'src/app/providers/_interfaces/kbk';
import { IErknmStatuses } from 'src/app/providers/_interfaces/knd-inspections';
import { IKosgu } from 'src/app/providers/_interfaces/kosgu';
import { ILicenseRegistry, ILicenseActivityWasteRegistry, ILicensingOrgs } from 'src/app/providers/_interfaces/licenses.interface';
import { ILayersMap } from 'src/app/providers/_interfaces/map.interface';
import { IOkei, IMeasureUnits } from 'src/app/providers/_interfaces/measure-units.interface';
import { IOkopfs } from 'src/app/providers/_interfaces/okopfs.interface';
import { IOkpds, IOkpds2, ITnveds, ITnvedsNormalized } from 'src/app/providers/_interfaces/okpds.interface';
import { ISupervisionChangeReasons, IOnvosN, IGroroN, NsiOnvosMouthType, IControlDirections, IRiskCategoryDict, IReestrRiskCriteriaItem, INegativeImpactCategoryCriteria, IOnvosRequestPermittingDocumentTypes, IOnvos, IOnvNotRegisteredReasons } from 'src/app/providers/_interfaces/onvos/onvos.interface';
import { IPacksMaterials } from 'src/app/providers/_interfaces/packs-materials.interface';
import { IAccrualTypes, IAccrualDocumentTypes, IEnvironmentTaxableGoods, IDisposalNormativsPeriod } from 'src/app/providers/_interfaces/payments-managment.response.interface';
import { NsiWastePurpose, NsiTreatmentPlant } from 'src/app/providers/_interfaces/pek/pek.interface';
import { IPermittingDocumentType } from 'src/app/providers/_interfaces/permitting-document.interface';
import { IRegionDict } from 'src/app/providers/_interfaces/region';
import { IReportStatus, IStatusesByCode, IReportsTemplates, IReportRejectReasons, IReportStatusFilter } from 'src/app/providers/_interfaces/report.interface';
import { Foiv, SmevTemplate, InquiryKind, Smev4Datamart, SmevInquiryKindTemplate } from 'src/app/providers/_interfaces/smev.interface';
import { IStateServices, IStateServiceDenialReasons } from 'src/app/providers/_interfaces/state.services.interface';
import { IPollutants } from 'src/app/providers/_interfaces/stationary-source-fact.interface';
import { SupervisionCriteria } from 'src/app/providers/_interfaces/supervision-criteria.interface';
import { TerritoryOrg } from 'src/app/providers/_interfaces/territory.org';
import { ITypeOfIncome } from 'src/app/providers/_interfaces/type-of-income';
import { UserRole, IUser, IUserProfiles } from 'src/app/providers/_interfaces/user.interface';
import { IWasteHazardClasses } from 'src/app/providers/_interfaces/waste-hazard-classes.interface';
import { getYearsForDictionary } from 'src/app/providers/_utils/utils';

export type DictionaryDaDataName = keyof DictionaryOfDaData;
export type DictionaryName = keyof DictionaryOf;

export const DICT_DADATA: { [K in DictionaryDaDataName]: DictionaryStorage<DictionaryOfDaData[K]> } = {
  okved2: new Dictionary<IDictionaryItem>(),
  address: new Dictionary<IDictionaryItem>(),
  bank: new Dictionary<IDictionaryItem>(),
  party: new Dictionary<IDictionaryItem>(),
  partyLegal: new Dictionary<IDictionaryItem>(),
  partyIndividual: new Dictionary<IDictionaryItem>(),
};

export const DICT: { [K in DictionaryName]: DictionaryStorage<DictionaryOf[K]> } = {
  ...DICT_DADATA,
  datasource_template: new Dictionary(),
  officials: new Dictionary(),
  nsi_attached_documents_kinds: new Dictionary().setUrl('/api/svc/nsi/attached_documents_kinds').loadAll(),
  onvos_rejection_reasons: new Dictionary().setUrl('/api/svc/nsi/onvos_rejection_reasons').loadAll(),
  datasource_list: new Dictionary().setUrl('/api/svc/api-export/datasources').loadAll(),
  onvos_geo_license_orgs: new Dictionary()
    .setUrl('/api/svc/nsi/onvos-geo-license-orgs')
    .setItemUrl('/api/svc/nsi/onvos-geo-license-orgs')
    .loadAll(),
  transfer_reason_supervisory_territory_org: new Dictionary<ISupervisionChangeReasons>()
    .setUrl('/api/svc/nsi/supervision-change-reasons')
    .setItemUrl('/api/svc/nsi/supervision-change-reason'),
  permitted_document_end_date_reason: new Dictionary<ISupervisionChangeReasons>()
    .setUrl('/api/svc/nsi/document-prolongation-reasons')
    .setItemUrl('/api/svc/nsi/document-prolongation-reason'),
  all_report_years: new Dictionary().setUrl('/api/svc/nsi/state_services/report_allowed_years'),
  territory_org_id_all_no_delete: new Dictionary<TerritoryOrg>().setUrl('/api/svc/nsi/orgs-with-inactive').loadAll(),
  foiv: new Dictionary<Foiv>().setUrl('/api/svc/nsi/foiv').loadAll(),
  foiv_scope_by_datamart: new Dictionary<Foiv>().setUrl('/api/svc/nsi/foiv?scope[hasSmev4Datamart]=true').setItemUrl('/api/svc/nsi/foiv'),
  smev_templates: new Dictionary<SmevTemplate>().setUrl('/api/svc/smev/templates').loadAll(),
  smev_inquiry_kinds: new Dictionary<InquiryKind>().setUrl('/api/svc/smev3/internal/inquiry_kinds?relations=templates').loadAll(),
  smev4_datamarts: new Dictionary<Smev4Datamart>().setUrl('/api/svc/smev4/internal/datamarts'),
  template_categories: new Dictionary<ITemplateCategory>().setUrl('/api/svc/xls-export/template-categories').loadAll(),
  onv_code_list: new Dictionary<IOnvosN>()
    .setUrl('/api/svc/onv/open/list-number')
    .beforeRequestModFunc(onv_code_list_brm)
    .setInitKey('number'),
  groro_code_list: new Dictionary<IGroroN>()
    .setUrl('/api/svc/groro/list-number')
    .beforeRequestModFunc(groro_code_list_brm)
    .setInitKey('groro_number'),
  nsi_rpn_employees: new Dictionary().setUrl('/api/svc/nsi/rpn_employees?relations=post').setItemUrl('/api/svc/nsi/rpn_employees'),
  nsi_employees_positions: new Dictionary().setUrl('/api/svc/nsi/posts').setItemUrl('/api/svc/nsi/post').loadAll(),
  nsi_employees_basic_post_without_cache: new Dictionary().setUrl('/api/svc/nsi/v2/rpn-structure/basic-post'),
  nsi_employees_basic_post: new Dictionary().setUrl('/api/svc/nsi/v2/rpn-structure/basic-post').loadAll(),
  nsi_employees_post: new Dictionary().setUrl('/api/svc/nsi/v2/rpn-structure/post?relations=department,basic_post').loadAll(),
  nsi_employees_post_without_cache: new Dictionary()
    .setUrl('/api/svc/nsi/v2/rpn-structure/post?relations=department,basic_post')
    .setItemUrl('/api/svc/nsi/v2/rpn-structure/post'),
  nsi_employees_department: new Dictionary()
    .setUrl('/api/svc/nsi/v2/rpn-structure/department')
    .setItemUrl('/api/svc/nsi/v2/rpn-structure/department')
    .loadAll(),
  nsi_employees_department_without_cache: new Dictionary()
    .setUrl('/api/svc/nsi/v2/rpn-structure/department')
    .setItemUrl('/api/svc/nsi/v2/rpn-structure/department'),
  nsi_approval_routes: new Dictionary().setUrl('/api/svc/nsi/v2/approval-routes').loadAll(),
  nsi_approval_routes_without_cache: new Dictionary()
    .setUrl('/api/svc/nsi/v2/approval-routes')
    .setItemUrl('/api/svc/nsi/v2/approval-routes'),
  dpnvos_ratio: new Dictionary().setUrl('/api/svc/nsi/dpnvos_ratio').setItemUrl('/api/svc/nsi/dpnvos_ratio').loadAll(),
  advance_payment_ways: new Dictionary().setUrl('/api/svc/nsi/advance_payment_ways').setItemUrl('/api/svc/nsi/advance_payment_ways'),
  reestr_pm_type: new Dictionary().setUrl('/api/svc/nsi/reestr_pm_type').setItemUrl('/api/svc/nsi/reestr_pm_type'),
  nsi_onvos_mouth_type: new Dictionary<NsiOnvosMouthType>()
    .setUrl('/api/svc/nsi/nsi_onvos_mouth_type')
    .setItemUrl('/api/svc/nsi/nsi_onvos_mouth_type'),
  nsi_tnveds: new Dictionary<NsiOnvosMouthType>()
    .setUrl('/api/svc/nsi/nsi_tnveds')
    .setItemUrl('/api/svc/nsi/nsi_tnveds'),
  nsi_waste_purpose: new Dictionary<NsiWastePurpose>()
    .setUrl('/api/svc/nsi/nsi_waste_purpose')
    .setItemUrl('/api/svc/nsi/nsi_waste_purpose'),
  nsi_treatment_plant: new Dictionary<NsiTreatmentPlant>()
    .setUrl('/api/svc/nsi/nsi_treatment_plant')
    .setItemUrl('/api/svc/nsi/nsi_treatment_plant'),
  nsi_cites_kinds: new Dictionary<NsiCitesKinds>().setUrl('/api/svc/nsi/nsi_cites_kinds').setItemUrl('/api/svc/nsi/nsi_cites_kinds'),
  nsi_countries: new Dictionary<ICountries>().setUrl('/api/svc/nsi/countries').setItemUrl('/api/svc/nsi/country'),
  nsi_cites_targets: new Dictionary<NsiCitesSourcesTarget>()
    .setUrl('/api/svc/nsi/nsi_cites_targets')
    .setItemUrl('/api/svc/nsi/nsi_cites_targets'),
  nsi_cites_targets_traveling_exhibition: new Dictionary<NsiCitesSourcesTarget>()
    .setUrl('/api/svc/nsi/nsi_cites_targets?filter[id]=4')
    .setItemUrl('/api/svc/nsi/nsi_cites_targets'),
  nsi_cites_sources: new Dictionary<NsiCitesSourcesTarget>()
    .setUrl('/api/svc/nsi/nsi_cites_sources')
    .setItemUrl('/api/svc/nsi/nsi_cites_sources'),
  nsi_cites_designations: new Dictionary<NsiCitesDesignations>()
    .setUrl('/api/svc/nsi/nsi_cites_designations')
    .setItemUrl('/api/svc/nsi/nsi_cites_designations'),
  nsi_cites_attachments: new Dictionary<NsiCitesAttachments>()
    .setUrl('/api/svc/nsi/nsi_cites_attachments')
    .setItemUrl('/api/svc/nsi/nsi_cites_attachments'),
  environment_component_id: new Dictionary()
    .setUrl('/api/svc/nsi/environment-components')
    .setItemUrl('/api/svc/nsi/environment-components')
    .addFilters({ is_active: true })
    .loadAll(),
  roles: new Dictionary<UserRole>().setUrl('/api/svc/nsi/roles'),
  statuses: new Dictionary<IReportStatus>().setUrl('/api/svc/nsi/statuses').setItemUrl('/api/svc/nsi/status').loadAll(),
  statuses_without_cache: new Dictionary<IReportStatus>().setUrl('/api/svc/nsi/statuses').setItemUrl('/api/svc/nsi/status'),
  license_registry_statuses: new Dictionary<ILicenseRegistry>()
    .setUrl('/api/svc/nsi/law-statuses')
    .setItemUrl('/api/svc/nsi/law-status')
    .loadAll(),
  license_registry_statuses_by_display_name: new Dictionary<ILicenseRegistry>()
    .setUrl('/api/svc/nsi/law-statuses-by-display-name')
    .loadAll(),
  license_activity_waste_registry_state_service: new Dictionary<ILicenseRegistry>()
    .setUrl('/api/svc/nsi/state_services?filter%5Bid%5D=45,49,50,150,152')
    .loadAll(),
  municipalities: new Dictionary<TerritoryOrg>().setUrl('/api/svc/nsi/municipalities').setItemUrl('/api/svc/nsi/municipality'),
  municipalities_all: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/municipalities')
    .setItemUrl('/api/svc/nsi/municipality')
    .setInitKey('oktmo'),
  municipalities_not_null: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/municipalities?filter[is_active]=notnull')
    .setItemUrl('/api/svc/nsi/municipality')
    .setInitKey('oktmo'),
  nsi_reestr_object_control_type: new Dictionary()
    .setUrl('/api/svc/nsi/reestr_object_control_type')
    .setItemUrl('/api/svc/nsi/reestr_object_control_type')
    .loadAll(),
  nsi_reestr_object_species: new Dictionary()
    .setUrl('/api/svc/nsi/reestr_object_species')
    .setItemUrl('/api/svc/nsi/reestr_object_species')
    .loadAll(),
  nsi_reestr_object_control_types: new Dictionary().setUrl('/api/svc/nsi/reestr_object_control_types').setItemUrl('/api/svc/nsi/reestr_object_control_types').loadAll(),
  nsi_objects_declared_categories: new Dictionary().setUrl('/api/svc/nsi/objects-declared-categories').setItemUrl('/api/svc/nsi/objects-declared-category').loadAll(),
  nsi_appointment_categories: new Dictionary()
    .setUrl('/api/svc/nsi/appointment_categories')
    .setItemUrl('/api/svc/nsi/appointment_categories')
    .loadAll(),
  nsi_reestr_object_subspecies: new Dictionary().setUrl('/api/svc/nsi/reestr_object_subspecies').loadAll(),
  nsi_final_registry_category: new Dictionary().setUrl('/api/svc/nsi/final_registry_category').loadAll(),
  nsi_beginning_registry_category: new Dictionary().setUrl('/api/svc/nsi/beginning_registry_category').loadAll(),
  mineral_resources: new Dictionary().setUrl('/api/svc/nsi/mineral_resources').setItemUrl('/api/svc/nsi/mineral_resources'),
  fgen_type: new Dictionary().setUrl('/api/svc/nsi/fgen_type').setItemUrl('/api/svc/nsi/fgen_type'),
  fgen_object: new Dictionary()
    .setUrl('/api/svc/reestr/v2/fgen_object?relations=object_information,applicant_information')
    .setItemUrl('/api/svc/reestr/v2/fgen_object?relations=object_information,applicant_information'),
  fgen_executor_type: new Dictionary().setUrl('/api/svc/nsi/fgen_executor_type').setItemUrl('/api/svc/nsi/fgen_executor_type'),
  fgen_construct_status: new Dictionary().setUrl('/api/svc/nsi/fgen_construct_status').setItemUrl('/api/svc/nsi/fgen_construct_status'),
  fgen_vred_status: new Dictionary().setUrl('/api/svc/nsi/fgen_vred_status').setItemUrl('/api/svc/nsi/fgen_vred_status'),
  fgen_construct_kind: new Dictionary().setUrl('/api/svc/nsi/fgen_construct_kind').setItemUrl('/api/svc/nsi/fgen_construct_kind'),
  fgen_cap_feature: new Dictionary().setUrl('/api/svc/nsi/fgen_cap_feature').setItemUrl('/api/svc/nsi/fgen_cap_feature'),
  fgen_control_doc_type: new Dictionary().setUrl('/api/svc/nsi/fgen_control_doc_type').setItemUrl('/api/svc/nsi/fgen_control_doc_type'),
  reestr_knm_type: new Dictionary().setUrl('/api/svc/nsi/reestr_knm_type').setItemUrl('/api/svc/nsi/reestr_knm_type'),
  reestr_check_type: new Dictionary().setUrl('/api/svc/nsi/reestr_check_type').setItemUrl('/api/svc/nsi/reestr_check_type'),
  fgen_violations_type: new Dictionary().setUrl('/api/svc/nsi/fgen_violations_type').setItemUrl('/api/svc/nsi/fgen_violations_type'),
  kbk: new Dictionary<IKbk>().setUrl('/api/payments-management/kbks').setItemUrl('/api/payments-management/kbk'),
  kbks: new Dictionary<IKbk>().setUrl('/api/payments-management/kbks').setItemUrl('/api/payments-management/kbks').setInitKey('kbk'),
  kbks_notnull: new Dictionary<IKbk>()
    .setUrl('/api/payments-management/kbks?filter[is_active]=notnull')
    .setItemUrl('/api/payments-management/kbks')
    .setInitKey('kbk'),
  nsi_addcharge_request_types: new Dictionary<IKbk>()
    .setUrl('/api/svc/nsi/addcharge_request_types')
    .loadAll(),
  nsi_recycler_request_types: new Dictionary<IKbk>()
    .setUrl('/api/svc/nsi/recycler_request_type')
    .loadAll(),
  type_of_income: new Dictionary<ITypeOfIncome>()
    .setUrl('/api/payments-management/types_of_income')
    .setItemUrl('/api/payments-management/type_of_income')
    .loadAll(),
  type_of_income_for_act_create: new Dictionary<ITypeOfIncome>()
    .setUrl('/api/payments-management/types_of_income')
    .setItemUrl('/api/payments-management/type_of_income')
    .transformFunc(type_of_income_for_act_create_transform),
  kosgu: new Dictionary<IKosgu>().setUrl('/api/payments-management/kosgus').setItemUrl('/api/payments-management/kosgu'),
  work_performed_types: new Dictionary().setUrl('/api/svc/nsi/work-performed-types').setItemUrl('/api/svc/nsi/work-performed-type'),
  risk_category: new Dictionary().setUrl('/api/svc/nsi/risk-categories').setItemUrl('/api/svc/nsi/risk-category').loadAll(),
  nsi_knm_separate_directions: new Dictionary().setUrl('/api/svc/nsi/knm_separate_directions').loadAll(),
  assigment_criteria: new Dictionary().setUrl('/api/svc/nsi/assigment-criteria').loadAll(),
  object_declared_category_id: new Dictionary()
    .setUrl('/api/svc/nsi/objects-declared-categories')
    .setItemUrl('/api/svc/nsi/objects-declared-category')
    .loadAll(),
  state_environmental_supervision_levels: new Dictionary()
    .setUrl('/api/svc/nsi/state-environmental-supervision-levels')
    .setItemUrl('/api/svc/nsi/state-environmental-supervision-level')
    .loadAll(),
  control_directions: new Dictionary<IControlDirections>()
    .setUrl('/api/svc/nsi/control-directions?fields=id,name,is_active,created_at,level')
    .setItemUrl('/api/svc/nsi/control-directions')
    .loadAll(),
  supervision_criteria: new Dictionary<SupervisionCriteria>()
    .setUrl(
      '/api/svc/nsi/supervision-criteria' +
      '?filter[is_secure_criteria]=false' +
      '&fields=id,name,title,tag,is_active,parent_id,created_at,control_direction_id,is_secure_criteria',
    )
    .loadAll(),
  supervision_criteria_all: new Dictionary<SupervisionCriteria>()
    .setUrl(
      '/api/svc/nsi/supervision-criteria' +
      '?filter[is_active]=notnull&filter[is_secure_criteria]=false' +
      '&fields=id,name,title,tag,is_active,parent_id,created_at,control_direction_id,is_secure_criteria',
    )
    .loadAll(),
  supervision_criteria_secure: new Dictionary<SupervisionCriteria>()
    .setUrl(
      '/api/svc/nsi/supervision-criteria' +
      '?filter[is_secure_criteria]=true' +
      '&fields=id,name,title,tag,is_active,parent_id,created_at,control_direction_id,is_secure_criteria',
    )
    .loadAll(),
  supervision_criteria_all_secure: new Dictionary<SupervisionCriteria>()
    .setUrl(
      '/api/svc/nsi/supervision-criteria' +
      '?filter[is_secure_criteria]=true&filter[is_active]=notnull' +
      '&fields=id,name,title,tag,is_active,parent_id,created_at,control_direction_id,is_secure_criteria',
    )
    .loadAll(),
  report_statuses: new Dictionary<IReportStatus>().setUrl('/api/svc/nsi/reports-status-list').loadAll(),
  statuses_by_code: new Dictionary<IStatusesByCode>().setUrl('/api/svc/nsi/statuses-by-code'),
  risk_category_calc_criteries: new Dictionary<IRiskCategoryDict>()
    .setUrl('/api/svc/nsi/risk-category-calc-criteries')
    .setItemUrl('/api/svc/nsi/risk-category-calc-criteria'),
  nsi_reestr_risk_criteria: new Dictionary<IReestrRiskCriteriaItem>()
    .setUrl('/api/svc/nsi/reestr_risk_criteria')
    .setItemUrl('/api/svc/nsi/reestr_risk_criteria'),
  extended_profiles: new Dictionary<IExtendedProfile>()
    .setUrl('/api/svc/nsi/extended-profiles')
    .setItemUrl('/api/svc/nsi/extended-profile'),
  pollutants: new Dictionary<IPollutants>().setUrl('/api/svc/nsi/pollutants').setItemUrl('/api/svc/nsi/pollutant'),
  monitoring_pollutants: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutants?filter[monitoring_code]=notnull')
    .setItemUrl('/api/svc/nsi/pollutant'),
  nsi_fgn_review_result_types: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/fgn_review_result_types')
    .setItemUrl('/api/svc/nsi/fgn_review_result_types')
    .loadAll(),
  monitoring_pollutants_cerf: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutants?filter[pollutant_types_group_id]=1&filter[is_2909_resolution]=true')
    .loadAll(),
  pollutants_with_taxes: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutant-types-group/2/with-taxes')
    .setItemUrl('/api/svc/nsi/pollutant-types-group/2/with-taxe'),
  air_pollutants_with_taxes: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutant-types-group/1/with-taxes')
    .setItemUrl('/api/svc/nsi/pollutant-types-group/1/with-taxe'),
  map_objects_types: new Dictionary<ILayersMap>().setUrl('/api/svc/map/layers').setItemUrl('/api/svc/map/layers'),
  air_pollutants: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutants?filter[pollutant_types_group_id]=1')
    .setItemUrl('/api/svc/nsi/pollutant')
    .addFilters({ is_active: true }),
  water_pollutants: new Dictionary<IPollutants>()
    .setUrl('/api/svc/nsi/pollutants?filter[pollutant_types_group_id]=2')
    .setItemUrl('/api/svc/nsi/pollutant')
    .addFilters({ is_active: true }),
  federal_district_id: new Dictionary().setUrl('/api/svc/nsi/federal-districts').setItemUrl('/api/svc/nsi/federal-district'),
  territory_org_cache: new Dictionary<TerritoryOrg>().setUrl('/api/svc/nsi/orgs?order_by[id]=asc').setItemUrl('/api/svc/nsi/org').loadAll(),
  territory_org_is_active_notnull: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/orgs?order_by[id]=asc&filter[is_active]=notnull')
    .setItemUrl('/api/svc/nsi/org')
    .loadAll(),
  territory_org_id: new Dictionary<TerritoryOrg>().setUrl('/api/svc/nsi/orgs').setItemUrl('/api/svc/nsi/org'),
  territory_org_id_transfer: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/orgs?scope[byOnvSpecificTo]=notnull')
    .setItemUrl('/api/svc/nsi/org'),
  territory_org_id_gee: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/orgs?scope[orderByNameCaFirst]&filter_contains[acceptable_state_service_ids]=160')
    .setItemUrl('/api/svc/nsi/org'),
  territory_org_id_cites: new Dictionary<TerritoryOrg>()
    .setUrl('/api/svc/nsi/orgs?filter_contains[acceptable_state_service_ids]=104')
    .setItemUrl('/api/svc/nsi/org'),
  territory_org_id_all: new Dictionary<TerritoryOrg>().setUrl('/api/svc/nsi/orgs-with-trashed').setItemUrl('/api/svc/nsi/org'),
  region_allowed: new Dictionary<IRegionDict>()
    .setUrl('/api/svc/nsi/regions/allowed?order_by[id]=asc')
    .setItemUrl('/api/svc/nsi/region')
    .loadAll(),
  region: new Dictionary<IRegionDict>().setUrl('/api/svc/nsi/regions').setItemUrl('/api/svc/nsi/region'),
  region_cache: new Dictionary<IRegionDict>().setUrl('/api/svc/nsi/regions?order_by[id]=asc').setItemUrl('/api/svc/nsi/region').loadAll(),
  report_template_waste: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export?search=2tpwaste'),
  report_template_air: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export?search=2tpair'),
  report_template_onvos: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export?search=onv'),
  report_template_envharm: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export?search=irv'),
  report_template_license: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export?search=lic'),
  license_issue_order_fkkos: new Dictionary<IReportsTemplates>().setUrl(
    '/api/svc/xls-export?filter[is_active]=false&filter[code]=license_issue_order_fkkos',
  ),
  license_replace_order_fkkos: new Dictionary<IReportsTemplates>().setUrl(
    '/api/svc/xls-export?filter[is_active]=false&filter[code]=license_replace_order_fkkos',
  ),
  license_issue_place_fkkos: new Dictionary<IReportsTemplates>().setUrl(
    '/api/svc/xls-export?filter[is_active]=false&filter[code]=license_issue_place_fkkos',
  ),
  license_replace_place_fkkos: new Dictionary<IReportsTemplates>().setUrl(
    '/api/svc/xls-export?filter[is_active]=false&filter[code]=license_replace_place_fkkos',
  ),
  license_registry_dict: new Dictionary<ILicenseActivityWasteRegistry>()
    .setUrl('/api/svc/license-activity-waste/registry')
    .setItemUrl('/api/svc/license-activity-waste/registry'),
  registry_by_inn: new Dictionary<ILicenseActivityWasteRegistry>().setUrl('/api/svc/license-activity-waste/registry/by_inn'),
  licensing_orgs: new Dictionary<ILicensingOrgs>().setUrl('/api/svc/nsi/licensing-orgs').setItemUrl('/api/svc/nsi/licensing-org'),
  cites_certificate_dict: new Dictionary()
    .setUrl('/api/svc/cites/v2/cites_certificate_import?relations=certificate')
    .setItemUrl('/api/svc/cites/v2/cites_certificate_import'),
  doer_id: new Dictionary<IUser>().setUrl('/api/svc/nsi/doers').setItemUrl('/api/svc/nsi/doer'),
  nsi_penalty_type: new Dictionary().setUrl('/api/svc/nsi/penalty_types').setItemUrl('/api/svc/nsi/penalty_types').loadAll(),
  nsi_reestr_control_type: new Dictionary().setUrl('/api/svc/nsi/reestr_control_type').loadAll(),
  nsi_key_criterias: new Dictionary().setUrl('/api/svc/nsi/key_criterias').setItemUrl('/api/svc/nsi/key_criteria'),
  knm_passport_id: new Dictionary().setUrl('/api/svc/knd/v2/knd_card?relations=applicant_information,fgn_objects,common'),
  atex_expert_id: new Dictionary().setUrl('/api/svc/reestr/v2/atex_object?relations=applicant_information,attestation_information'),
  nsi_negative_impact_categories: new Dictionary()
    .setUrl('/api/svc/nsi/negative-impact-categories')
    .setItemUrl('/api/svc/nsi/negative-impact-category')
    .loadAll(),
  nsi_knd_inspection_types: new Dictionary()
    .setUrl('/api/svc/nsi/knd_inspection_types')
    .setItemUrl('/api/svc/nsi/knd_inspection_types')
    .loadAll(),
  nsi_fgen_expertise_type: new Dictionary()
    .setUrl('/api/svc/nsi/fgen_expertise_type')
    .setItemUrl('/api/svc/nsi/fgen_expertise_type')
    .loadAll(),
  nsi_discontinuation_reasons: new Dictionary()
    .setUrl('/api/svc/nsi/discontinuation_reasons')
    .setItemUrl('/api/svc/nsi/discontinuation_reasons')
    .loadAll(),
  nsi_reestr_document_type: new Dictionary()
    .setUrl('/api/svc/nsi/reestr_document_type')
    .setItemUrl('/api/svc/nsi/reestr_document_type')
    .loadAll(),
  nsi_registration_levels: new Dictionary()
    .setUrl('/api/svc/nsi/registration_level')
    .setItemUrl('/api/svc/nsi/registration_level'),
  nsi_recycler_documents_types: new Dictionary()
    .setUrl('/api/svc/nsi/recycler_documents_types')
    .setItemUrl('/api/svc/nsi/recycler_documents_type')
    .loadAll(),
  nsi_recycler_assessment_types: new Dictionary()
    .setUrl('/api/svc/nsi/recycler_assessment_types')
    .setItemUrl('/api/svc/nsi/recycler_assessment_type')
    .loadAll(),
  nsi_recycler_check_request_results: new Dictionary()
    .setUrl('/api/svc/nsi/recycler_check_request_results')
    .setItemUrl('/api/svc/nsi/recycler_check_request_result')
    .loadAll(),
  nsi_recycler_refusal_reasons: new Dictionary()
    .setUrl('/api/svc/nsi/recycler_refusal_reasons')
    .setItemUrl('/api/svc/nsi/recycler_refusal_reason')
    .loadAll(),
  public_reestr_fgen: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgen_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgen_list'),
  public_reestr_fggn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fggn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fggn_list'),
  public_reestr_fgzn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgzn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgzn_list'),
  public_reestr_fgohn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgohn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgohn_list'),
  public_reestr_fgotn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgotn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgotn_list'),
  public_reestr_fglesn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fglesn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fglesn_list'),
  public_reestr_fgan: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgan_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgan_list'),
  public_reestr_fgahn: new Dictionary()
    .setUrl('/api/svc/public-registries/reestr_fgahn_list')
    .setItemUrl('/api/svc/public-registries/reestr_fgahn_list'),
  nsi_technology_production_type: new Dictionary()
    .setUrl('/api/svc/nsi/origin_technology_production_type')
    .setItemUrl('/api/svc/nsi/origin_technology_production_type'),
  nsi_origin_technology_goods_type: new Dictionary()
    .setUrl('/api/svc/nsi/origin_technology_goods_type')
    .setItemUrl('/api/svc/nsi/origin_technology_goods_type'),
  nsi_origin_technology_process_type: new Dictionary()
    .setUrl('/api/svc/nsi/origin_technology_process_type')
    .setItemUrl('/api/svc/nsi/origin_technology_process_type'),
  nsi_reestr_knm_type: new Dictionary().setUrl('/api/svc/nsi/reestr_knm_type').setItemUrl('/api/svc/nsi/reestr_knm_type').loadAll(),
  nsi_knm_dl_positions: new Dictionary().setUrl('/api/svc/nsi/knm_dl_position').loadAll(),
  doer_by_org: new Dictionary<IUser>().setUrl('/api/svc/nsi/doers/filter-by-ter-org').setItemUrl('/api/svc/nsi/doers'),
  user_id: new Dictionary<IUser>().setUrl('/api/svc/nsi/users').setItemUrl('/api/svc/nsi/user'),
  user_profile_id: new Dictionary<IUserProfiles>().setUrl('/api/svc/nsi/user-profiles').setItemUrl('/api/svc/nsi/user-profile'),
  country_id: new Dictionary<ICountries>().setUrl('/api/svc/nsi/countries').setItemUrl('/api/svc/nsi/country'),
  state_services_all: new Dictionary<IStateServices>()
    .setUrl('/api/svc/nsi/state_services?relations=report_periods')
    .setItemUrl('/api/svc/nsi/state_services')
    .loadAll(),
  state_services: new Dictionary<IStateServices>().setUrl('/api/svc/nsi/state_services').setItemUrl('/api/svc/nsi/state_services'),
  okpds: new Dictionary<IOkpds>().setUrl('/api/svc/nsi/okpds').setItemUrl('/api/svc/nsi/okpd'),
  convertor_products: new Dictionary<IOkpds>().setUrl('/api/svc/reestr/v2/converter/production'),
  convertor_production_actualization: new Dictionary<IOkpds>().setUrl('/api/svc/reestr/v2/converter/production_actualization'),
  converter_recyclable: new Dictionary<IOkpds>().setUrl('/api/svc/reestr/v2/converter/recyclable'),
  okopfs: new Dictionary<IOkopfs>().setUrl('/api/svc/nsi/okopfs').setItemUrl('/api/svc/nsi/okopf'),
  okei: new Dictionary<IOkei>().setUrl('/api/svc/nsi/okei').setItemUrl('/api/svc/nsi/okei'),
  measureUnits: new Dictionary<IMeasureUnits>().setUrl('/api/svc/nsi/measure-units').setItemUrl('/api/svc/nsi/measure-unit'),
  wasteHazardClasses: new Dictionary<IWasteHazardClasses>()
    .setUrl('/api/svc/nsi/waste-hazard-classes')
    .setItemUrl('/api/svc/nsi/waste-hazard-class'),
  heatEnergyTypes: new Dictionary().setUrl('/api/svc/nsi/heat-energy-types').setItemUrl('/api/svc/nsi/heat-energy-type'),
  payment_management_accrual_types: new Dictionary<IAccrualTypes>()
    .setUrl('/api/svc/nsi/accrual-types?filter[is_active]=notnull')
    .setItemUrl('/api/svc/nsi/accrual-types'),
  payment_management_payment_types: new Dictionary<IAccrualTypes>()
    .setUrl('/api/svc/nsi/payment-types?filter[is_active]=notnull')
    .setItemUrl('/api/svc/nsi/payment-types'),
  nsi_payment_management_accrual_koap_articles: new Dictionary()
    .setUrl('/api/svc/nsi/accrual-koap-articles')
    .setItemUrl('/api/svc/nsi/accrual-koap-articles'),
  nsi_administrative_decision_types: new Dictionary()
    .setUrl('/api/svc/nsi/administrative_decision_types')
    .setItemUrl('/api/svc/nsi/administrative_decision_types'),
  nsi_administrative_accrual_statuses: new Dictionary()
    .setUrl('/api/svc/nsi/administrative_accrual_statuses')
    .setItemUrl('/api/svc/nsi/administrative_accrual_statuses'),
  nsi_administrative_protocol_results: new Dictionary()
    .setUrl('/api/svc/nsi/administrative_protocol_results')
    .setItemUrl('/api/svc/nsi/administrative_protocol_results'),
  nsi_administrative_reason_types: new Dictionary()
    .setUrl('/api/svc/nsi/administrative_reason_types')
    .setItemUrl('/api/svc/nsi/administrative_reason_types')
    .loadAll(),
  payment_management_operation_statuses: new Dictionary()
    .setUrl('/api/svc/nsi/accrual-operation-statuses')
    .setItemUrl('/api/svc/nsi/accrual-operation-statuses'),
  payment_management_counterparty_types: new Dictionary()
    .setUrl('/api/svc/nsi/counterparty-types')
    .setItemUrl('/api/svc/nsi/counterparty-types'),
  fkkos: new Dictionary<IFKKODict>().setUrl('/api/svc/nsi/fkkos').setItemUrl('/api/svc/nsi/fkko'),
  convertor_fkkos: new Dictionary<IFKKODict>().setUrl('/api/svc/reestr/v2/converter/fkko'),
  fkkos_groro_allowed: new Dictionary<IFKKODict>()
    .setUrl('/api/svc/nsi/fkkos?filter[has_allowed_groro]=true')
    .setItemUrl('/api/svc/nsi/fkko'),
  fkkos_groro_allowed_without_burial_prohibited: new Dictionary<IFKKODict>()
    .setUrl('/api/svc/nsi/fkkos?filter[has_allowed_groro]=true&filter[has_burial_prohibited]=false')
    .setItemUrl('/api/svc/nsi/fkko'),
  fkkos_licensed: new Dictionary<IFKKODict>().setUrl('/api/svc/nsi/fkkos?scope[licensed]').setItemUrl('/api/svc/nsi/fkko'),
  application_method_id: new Dictionary().setUrl('/api/svc/nsi/application-method').setItemUrl('/api/svc/nsi/application-method'),
  get_method_id_service: new Dictionary().setUrl('/api/svc/onv/nsi_get_methods').loadAll(),
  get_method_id: new Dictionary().setUrl('/api/svc/nsi/get-methods'),
  eek_get_method_id: new Dictionary().setUrl('/api/svc/nsi/get-methods?filter_json[state_service_ids]=112'),
  eek_duplicate_get_method_id: new Dictionary().setUrl('/api/svc/nsi/get-methods?filter_json[state_service_ids]=113'),
  eek_correction_get_method_id: new Dictionary().setUrl('/api/svc/nsi/get-methods?filter_json[state_service_ids]=114'),
  eek_certificates: new Dictionary()
    .setUrl('/api/svc/eek/v2/eek_certificate_import_issue?relations=certificate')
    .setItemUrl('/api/svc/eek/v2/eek_certificate_import_issue'),
  okveds_id: new Dictionary<IOkved>().setUrl('/api/svc/nsi/okveds').setItemUrl('/api/svc/nsi/okved').setInitKey('code'),
  okveds_by_id: new Dictionary<IOkved>().setUrl('/api/svc/nsi/okveds').setItemUrl('/api/svc/nsi/okved'),
  controlled_entities: new Dictionary().setUrl('/api/svc/controlled-entities/registry').setItemUrl('/api/svc/controlled-entities/registry'),
  criteria_nvos: new Dictionary<ICriteria>().setUrl('/api/svc/nsi/criteria_nvos').setItemUrl('/api/svc/nsi/criteria_nvos').loadAll(),
  criteria_nvos_all: new Dictionary<ICriteria>()
    .setUrl('/api/svc/nsi/criteria_nvos?filter[is_active]=notnull&fields=id,name,title,tag,is_active,parent_id,created_at')
    .setItemUrl('/api/svc/nsi/criteria_nvos?filter[is_active]=notnull&fields=id,name,title,tag,is_active,parent_id,created_at')
    .loadAll(),
  onvos_check_list_questions: new Dictionary<ICheckListQuestionsInstance>()
    .setUrl('/api/svc/nsi/onvos-check-list-questions')
    .setItemUrl('/api/svc/nsi/onvos-check-list-questions')
    .loadAll(),
  control_categories: new Dictionary<IControlCategory>()
    .setUrl('/api/svc/nsi/control_categories')
    .setItemUrl('/api/svc/nsi/control_categories')
    .loadAll(),
  nvos_category_criteria: new Dictionary<INegativeImpactCategoryCriteria>()
    .setUrl('/api/svc/nsi/negative-impact-category-criteria')
    .loadAll(),
  nvos_reduce_measures_directions: new Dictionary()
    .setUrl('/api/svc/nsi/nvos-reduce-measures-directions')
    .setItemUrl('/api/svc/nsi/nvos-reduce-measures-direction'),
  gee_document_types: new Dictionary<IGeeDocumentsTypes>()
    .setUrl('/api/svc/nsi/gee_document_types')
    .setItemUrl('/api/svc/nsi/gee_document_type'),
  gee_get_methods: new Dictionary()
    .setUrl('/api/svc/nsi/get-methods?filter_json[state_service_ids]=160'),
  onvos_request_permitting_document_types: new Dictionary<IOnvosRequestPermittingDocumentTypes>()
    .setUrl('/api/svc/nsi/onvos-request-permitting-document-types')
    .setItemUrl('/api/svc/nsi/onvos-request-permitting-document-type'),
  permitting_document_types: new Dictionary<IPermittingDocumentType>()
    .setUrl('/api/svc/nsi/permitting-document-types')
    .setItemUrl('/api/svc/nsi/permitting-document-type'),
  nsi_payment_accrual_document_types: new Dictionary<IAccrualDocumentTypes>()
    .setUrl('/api/svc/nsi/accrual-document-types')
    .setItemUrl('/api/svc/nsi/accrual-document-types'),
  nsi_payment_management_person_documents: new Dictionary()
    .setUrl('/api/svc/nsi/payment_management_person_document')
    .setItemUrl('/api/svc/nsi/payment_management_person_document'),
  oro_monitoring_level_id: new Dictionary()
    .setUrl('/api/svc/nsi/oro-monitoring-levels')
    .setItemUrl('/api/svc/nsi/oro-monitoring-level')
    .loadAll(),
  hydronyms: new Dictionary<IHydronyms>().setUrl('/api/svc/nsi/hydronyms').setItemUrl('/api/svc/nsi/hydronym'),
  hydronym_id: new Dictionary<IHydronyms>().setUrl('/api/svc/nsi/hydronyms').setItemUrl('/api/svc/nsi/hydronym'),
  hydronym_types_id: new Dictionary<IHydronymType>().setUrl('/api/svc/nsi/hydronym-types').setItemUrl('/api/svc/nsi/hydronym-type'),
  seas: new Dictionary<ISeas>().setUrl('/api/svc/nsi/seas').setItemUrl('/api/svc/nsi/seas'),
  sea_locations: new Dictionary().setUrl('/api/svc/nsi/sea-location').setItemUrl('/api/svc/nsi/sea-location'),
  water_use_kinds: new Dictionary().setUrl('/api/svc/nsi/water-use-kinds').setItemUrl('/api/svc/nsi/water-use-kind'),
  water_use_purposes: new Dictionary().setUrl('/api/svc/nsi/water-use-purposes').setItemUrl('/api/svc/nsi/water-use-purpose'),
  fishery_importance_categories: new Dictionary().setUrl('/api/svc/nsi/fishery-importance-categories'),
  emission_object_types: new Dictionary()
    .setUrl('/api/svc/nsi/emission-object-types')
    .setItemUrl('/api/svc/nsi/emission-object-type')
    .loadAll(),
  emission_source_types: new Dictionary()
    .setUrl('/api/svc/nsi/emission-source-types')
    .setItemUrl('/api/svc/nsi/emission-source-type')
    .loadAll(),
  license_registry: new Dictionary<ILicenseRegistry>()
    .setUrl('/api/svc/license-activity-waste/registry')
    .setItemUrl('/api/svc/license-activity-waste/registry'),
  my_onvos: new Dictionary<IOnvos>().setUrl('/api/svc/onv/my').setItemUrl('/api/svc/onv'),
  my_onvos_by_number: new Dictionary<IOnvos>().setUrl('/api/svc/onv/my').setInitKey('number').setItemUrl('/api/svc/onv'),
  onv_not_registered_reasons: new Dictionary<IOnvNotRegisteredReasons>()
    .setUrl('/api/svc/nsi/onv-not-registered-reasons')
    .setItemUrl('/api/svc/nsi/onv-not-registered-reason'),
  environment_components: new Dictionary()
    .setUrl('/api/svc/nsi/environment-components')
    .setItemUrl('/api/svc/nsi/environment-components')
    .loadAll(),
  waste_activities: new Dictionary().setUrl('/api/svc/nsi/waste-activities').setItemUrl('/api/svc/nsi/waste-activities').loadAll(),
  onvos_waste_activities: new Dictionary()
    .setUrl('/api/svc/nsi/onvos-waste-activities')
    .setItemUrl('/api/svc/nsi/onvos-waste-activities')
    .loadAll(),
  environment_taxable_goods: new Dictionary<IEnvironmentTaxableGoods>()
    .setUrl('/api/svc/nsi/environment-taxable-goods')
    .setItemUrl('/api/svc/nsi/environment-taxable-goods'),
  disposal_normativs_period: new Dictionary<IDisposalNormativsPeriod>()
    .setUrl('/api/svc/nsi/disposal_normativs_period')
    .setItemUrl('/api/svc/nsi/disposal_normativs_period')
    .loadAll(),
  rop_groups_in_periods: new Dictionary()
    .setUrl('/api/svc/nsi/rop_groups_in_periods')
    .setItemUrl('/api/svc/nsi/rop_groups_in_periods')
    .loadAll(),
  okpds2: new Dictionary<IOkpds2>().setUrl('/api/svc/nsi/okpds2').setItemUrl('/api/svc/nsi/okpd2'),
  tnveds: new Dictionary<ITnveds>().setUrl('/api/svc/nsi/tnveds').setItemUrl('/api/svc/nsi/tnved'),
  tnveds_normalize: new Dictionary<ITnvedsNormalized>()
    .setUrl('/api/svc/nsi/normalized-tnveds?order_by[code]=asc')
    .setItemUrl('/api/svc/nsi/normalized-tnved'),
  pack_groups: new Dictionary().setUrl('/api/svc/nsi/pack-groups').setItemUrl('/api/svc/nsi/pack-group'),
  packs_materials: new Dictionary<IPacksMaterials>().setUrl('/api/svc/nsi/packs-materials').setItemUrl('/api/svc/nsi/packs-material'),
  report_reject_reasons: new Dictionary<IReportRejectReasons>()
    .setUrl('/api/svc/nsi/report-reject-reasons')
    .setItemUrl('/api/svc/nsi/report-reject-reasons'),
  state_service_denial_reasons: new Dictionary<IStateServiceDenialReasons>()
    .setUrl('/api/svc/nsi/state-service-denial-reasons')
    .setItemUrl('/api/svc/nsi/state-service-denial-reason'),
  onvos_permitting_document_types: new Dictionary()
    .setUrl('/api/svc/nsi/onvos-permitting-document-types')
    .setItemUrl('/api/svc/nsi/onvos-permitting-document-type'),
  law_replace_reasons: new Dictionary().setUrl('/api/svc/nsi/law-replace-reasons').setItemUrl('/api/svc/nsi/law-replace-reasons'),
  law_termination_reasons: new Dictionary()
    .setUrl('/api/svc/nsi/law-termination-reasons')
    .setItemUrl('/api/svc/nsi/law-termination-reasons'),
  instructions: new Dictionary<IInstructionGroup>().setUrl('/api/svc/nsi/instruction-groups').loadAll(),
  enterprise_size_id: new Dictionary().setUrl('/api/svc/nsi/enterprise-sizes').setItemUrl('/api/svc/nsi/enterprise-size'),
  basis_for_nvos_change: new Dictionary()
    .setUrl('/api/svc/nsi/basis-for-nvos-change')
    .setItemUrl('/api/svc/nsi/basis-for-nvos-change')
    .loadAll(),
  association_types: new Dictionary().setUrl('/api/svc/nsi/association-types').setItemUrl('/api/svc/nsi/association-type'),
  person_documents: new Dictionary().setUrl('/api/svc/nsi/person-documents').setItemUrl('/api/svc/nsi/person-documents'),
  xml_export_templates: new Dictionary<IReportsTemplates>().setUrl('/api/svc/xls-export').loadAll(),
  epgu_tech_statuses: new Dictionary().setUrl('/api/svc/nsi/epgu-tech-statuses').loadAll(),
  payment_management_acts_statuses: new Dictionary<IReportStatus>()
    .setUrl('/api/svc/nsi/reconciliation-acts-statuses?filter[is_active]=notnull')
    .setItemUrl('/api/svc/nsi/reconciliation-acts-statuses'),
  payment_management_lk_declaration_statuses: new Dictionary()
    .setUrl('/api/payments-management/own-nsi/lk/declaration-statuses-by-code-by-user')
    .setItemUrl('/api/payments-management/own-nsi/lk/declaration-statuses-by-code-by-user'),
  payment_management_ksv_declaration_statuses: new Dictionary()
    .setUrl('/api/payments-management/own-nsi/ksv/declaration-statuses-by-code-by-user')
    .setItemUrl('/api/payments-management/own-nsi/ksv/declaration-statuses-by-code-by-user'),
  payment_management_document_types: new Dictionary().setUrl('/api/svc/nsi/document-types').setItemUrl('/api/svc/nsi/document-type'),
  erp_inspections: new Dictionary<IErpInspection>().setUrl('/api/svc/nsi/erp_inspections').setItemUrl('/api/svc/nsi/erp_inspections'),
  nsi_aggregate_conditions: new Dictionary<IAggregateConditions>()
    .setUrl('/api/svc/nsi/aggregate-conditions')
    .setItemUrl('/api/svc/nsi/aggregate-condition'),
  nsi_component_composition_method: new Dictionary()
    .setUrl('/api/svc/nsi/component-composition-method')
    .setItemUrl('/api/svc/nsi/component-composition-method')
    .loadAll(),
  nsi_hazard_class_criteria_type: new Dictionary().setUrl('/api/svc/nsi/hazard-class-criteria-type'),
  svc_waste_class_assignment_issue: new Dictionary()
    .setUrl('/api/svc/nvos-waste-certification/v2/waste_class_assignment_issue?relations=request.waste_information')
    .setItemUrl('/api/svc/nvos-waste-certification/v2/waste_class_assignment_issue'),
  constructor_waste_class_assignment_issue: new Dictionary()
    .setUrl('/api/svc/nvos-waste-certification/v2/waste_class_assignment_issue')
    .setItemUrl('/api/svc/nvos-waste-certification/v2/waste_class_assignment_issue'),
  chemical_components: new Dictionary().setUrl('/api/svc/nsi/chemical-components').setItemUrl('/api/svc/nsi/chemical-components'),
  requests_doc_type: new Dictionary<IRequestDocType>()
    .setUrl('/api/svc/nsi/requests-doc-type')
    .setItemUrl('/api/svc/nsi/requests-doc-type'),
  conclusion_type: new Dictionary().setUrl('/api/svc/nsi/conclusion-types').setItemUrl('/api/svc/nsi/conclusion-types'),
  state_reject_reason: new Dictionary().setUrl('/api/svc/nsi/state-reject-reason').setItemUrl('/api/svc/nsi/state-reject-reason'),
  oro_purposes: new Dictionary<IOroPurposes>().setUrl('/api/svc/nsi/oro_purposes').setItemUrl('/api/svc/nsi/oro_purposes'),
  oro_kinds: new Dictionary<IOroKinds>().setUrl('/api/svc/nsi/oro_kinds').setItemUrl('/api/svc/nsi/oro_kinds'),
  oro_protection_systems: new Dictionary().setUrl('/api/svc/nsi/oro_protection_systems').setItemUrl('/api/svc/nsi/oro_protection_systems'),
  oro_monitoring_levels: new Dictionary().setUrl('/api/svc/nsi/oro-monitoring-levels').setItemUrl('/api/svc/nsi/oro-monitoring-level'),
  identity_doc_kinds: new Dictionary<SupervisionCriteria>()
    .setUrl('/api/svc/nsi/identity_doc_kinds')
    .setItemUrl('/api/svc/nsi/identity_doc_kinds')
    .loadAll(),
  eek_kinds2: new Dictionary().setUrl('/api/svc/nsi/eek_kinds').setItemUrl('/api/svc/nsi/eek_kinds'),
  eek_measure_units: new Dictionary()
    .setUrl('/api/svc/nsi/eek_measure_units?filter[measure_unit]=true')
    .setItemUrl('/api/svc/nsi/eek_measure_units'),
  eek_unified_goods_list: new Dictionary()
    .setUrl('/api/svc/nsi/eek_unified_goods_list?filter[number]=2.6,2.7,2.8')
    .setItemUrl('/api/svc/nsi/eek_unified_goods_list'),
  oro_monitoring_systems: new Dictionary().setUrl('/api/svc/nsi/oro_monitoring_systems').setItemUrl('/api/svc/nsi/oro_monitoring_systems'),
  nsi_animals_kinds: new Dictionary().setUrl('/api/svc/nsi/animals_kinds').setItemUrl('/api/svc/nsi/animals_kinds'),
  nsi_animal_use_target: new Dictionary().setUrl('/api/svc/nsi/animal_use_target').setItemUrl('/api/svc/nsi/animal_use_target').loadAll(),
  nsi_animals_risk_category: new Dictionary().setUrl('/api/svc/nsi/animals_risk_category').setItemUrl('/api/svc/nsi/animals_risk_category'),
  nsi_risk_indicators: new Dictionary().setUrl('/api/svc/nsi/risk_indicator').setItemUrl('/api/svc/nsi/risk_indicator'),
  rprn_department: new Dictionary().setUrl('/api/svc/knd/v2/departament').setItemUrl('/api/svc/knd/v2/departament'),
  nsi_expertise_types: new Dictionary().setUrl('/api/svc/nsi/expertise_types').setItemUrl('/api/svc/nsi/expertise_type'),
  nsi_test_objects: new Dictionary().setUrl('/api/svc/nsi/test_objects'),
  nsi_action_users: new Dictionary().setUrl('/api/svc/nsi/action_user').setItemUrl('/api/svc/nsi/action_user'),
  nsi_rarity_category: new Dictionary().setUrl('/api/svc/nsi/rarity_category').setItemUrl('/api/svc/nsi/rarity_category'),
  nsi_animal_owner_activity: new Dictionary().setUrl('/api/svc/nsi/animal_owner_activity').setItemUrl('/api/svc/nsi/animal_owner_activity'),
  nsi_knd_types: new Dictionary().setUrl('/api/svc/nsi/knd_types').setItemUrl('/api/svc/nsi/knd_types'),
  quotas_plan_event: new Dictionary().setUrl('/api/svc/knd/quotas_plan_event'),
  knd_inspection_statuses: new Dictionary()
    .setUrl('/api/svc/nsi/knd_inspection_statuses')
    .setItemUrl('/api/svc/nsi/knd_inspection_statuses'),
  knd_quota_emission_view: new Dictionary()
    .setUrl('/api/svc/knd/knd_quota_emission_view'),
  nsi_address_types: new Dictionary()
    .setUrl('/api/svc/nsi/knd_inspection_address_types')
    .setItemUrl('/api/svc/nsi/knd_inspection_address_types'),
  nsi_inspection_types: new Dictionary().setUrl('/api/svc/nsi/knd_inspection_types').setItemUrl('/api/svc/nsi/knd_inspection_types'),
  organization_statuses: new Dictionary().setUrl('/api/svc/nsi/organization_statuses').setItemUrl('/api/svc/nsi/organization_statuses'),
  nsi_roskazna_territory_orgs: new Dictionary()
    .setUrl('/api/svc/nsi/roskazna-territory-orgs')
    .setItemUrl('/api/svc/nsi/roskazna-territory-orgs'),
  nsi_rpn_bank_requisites: new Dictionary().setUrl('/api/svc/nsi/rpn-bank-requisites').setItemUrl('/api/svc/nsi/rpn-bank-requisites'),
  nsi_greenhouse_sources_categories: new Dictionary()
    .setUrl('/api/svc/nsi/greenhouse_sources_categories')
    .setItemUrl('/api/svc/nsi/greenhouse_sources_categories'),
  nsi_greenhouse_gases: new Dictionary().setUrl('/api/svc/nsi/greenhouse_gases').setItemUrl('/api/svc/nsi/greenhouse_gases'),
  nsi_greenhouse_calculation_methods: new Dictionary()
    .setUrl('/api/svc/nsi/greenhouse_calculation_methods')
    .setItemUrl('/api/svc/nsi/greenhouse_calculation_methods'),
  nsi_oro_exclusion_reasons: new Dictionary().setUrl('/api/svc/nsi/oro_exclusion_reasons').setItemUrl('/api/svc/nsi/oro_exclusion_reasons'),
  report_datasources: new Dictionary().setUrl('/api/svc/nsi/datasources').setItemUrl('/api/svc/nsi/datasources').loadAll(),
  nsi_erul_decision_maker_roles: new Dictionary()
    .setUrl('/api/svc/nsi/erul_decision_maker_roles')
    .setItemUrl('/api/svc/nsi/erul_decision_maker_roles'),
  nsi_identity_doc_kinds: new Dictionary()
    .setUrl('/api/svc/nsi/identity_doc_kinds')
    .setItemUrl('/api/svc/nsi/identity_doc_kinds'),
  nsi_onvos_erul_decision_maker_roles: new Dictionary()
    .setUrl('/api/svc/nsi/nsi_onvos_erul_decision_maker_roles')
    .setItemUrl('/api/svc/nsi/nsi_onvos_erul_decision_maker_roles'),
  permanent_roles: new Dictionary().setUrl('/api/svc/nsi/roles?filter[is_permanent]=true'),
  rpn_access_groups: new Dictionary().setUrl('/api/svc/nsi/rpn_access_groups'),
  nsi_ozon_depleting_unifieds: new Dictionary()
    .setUrl('/api/svc/nsi/ozon_depleting_unifieds')
    .setItemUrl('/api/svc/nsi/ozon_depleting_unifieds')
    .loadAll(),
  gee_glavgos_statuses: new Dictionary().setUrl('/api/svc/nsi/gee_glavgos_statuses').loadAll(),
  gee_federal_projects: new Dictionary()
    .setUrl('/api/svc/nsi/gee_federal_projects')
    .setItemUrl('/api/svc/nsi/gee_federal_projects')
    .loadAll(),
  gee_objects: new Dictionary().setUrl('/api/svc/nsi/gee_objects').setItemUrl('/api/svc/nsi/gee_objects').loadAll(),
  gee_federal_law_174_articles: new Dictionary()
    .setUrl('/api/svc/nsi/gee_federal_law_174_articles')
    .setItemUrl('/api/svc/nsi/gee_federal_law_174_articles'),
  gee_object_types: new Dictionary().setUrl('/api/svc/nsi/gee_object_types').setItemUrl('/api/svc/nsi/gee_object_types').loadAll(),
  cites_transaction_codes: new Dictionary()
    .setUrl('/api/svc/nsi/cites_transaction_codes')
    .setItemUrl('/api/svc/nsi/cites_transaction_codes'),

  eek_state_services: new Dictionary<IStateServices>().setParentName('state_services').addMapper(eek_state_services_map),
  onv_request_type: new Dictionary<IStateServices>().setParentName('state_services').addMapper(onv_request_type_map),
  onv_request_statuses_id: new Dictionary<IReportStatusFilter>().setParentName('statuses').addMapper(onv_request_statuses_map),
  groro_statuses: new Dictionary<IStateServices>().setParentName('statuses').addMapper(groro_statuses_map),
  waste_class_assignment_statuses: new Dictionary<IStateServices>()
    .setParentName('statuses')
    .addMapper(waste_class_assignment_statuses_map),
  eek_petition_statuses: new Dictionary<IReportStatus>().setParentName('statuses').addMapper(eek_petition_statuses_map),
  nsi_reports_conclusion_type: new Dictionary()
    .setUrl('/api/svc/nsi/reports_conclusion_type')
    .setItemUrl('/api/svc/nsi/reports_conclusion_type'),
  nsi_dpnvos_sections: new Dictionary().setUrl('/api/svc/nsi/dpnvos_sections').setItemUrl('/api/svc/nsi/dpnvos_sections'),
  nsi_dpnvos_demand_statuses: new Dictionary().setUrl('/api/svc/nsi/dpnvos_demand_statuses').setItemUrl('/api/svc/nsi/dpnvos_demand_statuses'),
  nsi_dpnvos_act_statuses: new Dictionary().setUrl('/api/svc/nsi/dpnvos_act_statuses').setItemUrl('/api/svc/nsi/dpnvos_act_statuses'),
  nsi_cites_sexes: new Dictionary().setUrl('/api/svc/nsi/cites_sexes').setItemUrl('/api/svc/nsi/cites_sexes'),
  gee_ecv_topics: new Dictionary()
    .setUrl('/api/svc/nsi/gee_ecv_topics')
    .setItemUrl('/api/svc/nsi/gee_ecv_topics')
    .loadAll(),
  gee_request_issue: new Dictionary()
    .setUrl('/api/svc/gee/v2/gee_request_issue')
    .setItemUrl('/api/svc/gee/v2/gee_request_issue'),
  gee_ecv_incoming_topics: new Dictionary()
    .setUrl('/api/svc/nsi/gee_ecv_incoming_topics')
    .setItemUrl('/api/svc/nsi/gee_ecv_incoming_topics')
    .loadAll(),
  nsi_ecv_outgoing_topics: new Dictionary()
    .setUrl('/api/svc/nsi/gee_ecv_outgoing_topics')
    .setItemUrl('/api/svc/nsi/gee_ecv_outgoing_topics')
    .loadAll(),
  gecv_outgoings_statuses: new Dictionary(StaticDictValues.gecv_outgoings_statuses),
  nsi_commission_protocol_actions: new Dictionary()
    .setUrl('/api/svc/nsi/commission_protocol_actions')
    .setItemUrl('/api/svc/nsi/commission_protocol_actions')
    .loadAll(),
  nsi_commission_type: new Dictionary().setUrl('/api/svc/nsi/commission_type').setItemUrl('/api/svc/nsi/commission_type').loadAll(),
  nsi_commission_member_type: new Dictionary()
    .setUrl('/api/svc/nsi/commission_member_type')
    .setItemUrl('/api/svc/nsi/commission_member_type'),
  nsi_eroi_requests_topics: new Dictionary()
    .setUrl('/api/svc/nsi/eroi_requests_topics')
    .setItemUrl('/api/svc/nsi/eroi_requests_topics')
    .loadAll(),
  commission_object_list: new Dictionary().setUrl('/api/svc/reestr/v2/commission_object_list'),
  nsi_eek_areas: new Dictionary().setUrl('/api/svc/nsi/eek_areas').setItemUrl('/api/svc/nsi/eek_areas').loadAll(),
  nsi_applicant_types: new Dictionary()
    .setUrl('/api/svc/nsi/applicant_types?order_by[id]=asc')
    .setItemUrl('/api/svc/nsi/applicant_types')
    .loadAll(),
  nsi_erknm_statuses: new Dictionary<IErknmStatuses>().setUrl('/api/svc/nsi/erknm_statuses').loadAll(),
  nsi_erknm_statuses_filter: new Dictionary<IErknmStatuses>()
    .setParentName('nsi_erknm_statuses')
    .addMapper(nsi_erknm_statuses_filter_map)
    .loadAll(),
  nsi_knm_nature: new Dictionary().setUrl('/api/svc/nsi/knm_nature').loadAll(),
  nsi_envharm_pollutants_air_fees: new Dictionary()
    .setUrl('/api/svc/nsi/nsi_envharm_pollutants_air_fees')
    .setItemUrl('/api/svc/nsi/nsi_envharm_pollutants_air_fees')
    .loadAll(),
  nsi_envharm_change_consumer_prices: new Dictionary()
    .setUrl('/api/svc/nsi/nsi_envharm_change_consumer_prices')
    .setItemUrl('/api/svc/nsi/nsi_envharm_change_consumer_prices')
    .loadAll(),
  nsi_envharm_special_protection_coefficients: new Dictionary()
    .setUrl('/api/svc/nsi/nsi_envharm_special_protection_coefficients')
    .setItemUrl('/api/svc/nsi/nsi_envharm_special_protection_coefficients')
    .loadAll(),
  nsi_envharm_coefficients_increased_air_nmu: new Dictionary()
    .setUrl('/api/svc/nsi/nsi_envharm_coefficients_increased_air_nmu')
    .setItemUrl('/api/svc/nsi/nsi_envharm_coefficients_increased_air_nmu')
    .loadAll(),
  nsi_appointment_badges: new Dictionary()
    .setUrl('/api/svc/nsi/appointment_badges')
    .setItemUrl('/api/svc/nsi/appointment_badges')
    .loadAll(),
  nsi_knm_reason: new Dictionary().setUrl('/api/svc/nsi/knm_reason').loadAll(),
  quotas_payment_by_inn: new Dictionary().setUrl('/api/svc/knd/quotas_payment'),
  nsi_knm_reasons_relation: new Dictionary().setUrl('/api/svc/nsi/knm_reasons_relation').loadAll(),
  nsi_knm_prosecutor_org: new Dictionary().setUrl('/api/svc/nsi/knm_prosecutor_org').setItemUrl('/api/svc/nsi/knm_prosecutor_org'),
  nsi_knm_expert_type: new Dictionary().setUrl('/api/svc/nsi/knm_expert_type').loadAll(),
  nsi_collaborating_organizations_type: new Dictionary().setUrl('/api/svc/nsi/collaborating_organizations_type').loadAll(),
  atex_object_id: new Dictionary().setUrl('/api/svc/reestr/v2/atex_object?relations=applicant_information'),
  nsi_knm_ot: new Dictionary().setUrl('/api/svc/nsi/knm_ot').loadAll(),
  nsi_knm_mandatory: new Dictionary().setUrl('/api/svc/nsi/nsi_knm_mandatory').setItemUrl('/api/svc/nsi/nsi_knm_mandatory'),
  nsi_knm_checklist_question_types: new Dictionary()
    .setUrl('/api/svc/nsi/knm_checklist_question_types')
    .setItemUrl('/api/svc/nsi/knm_checklist_question_types'),
  nsi_knm_checklist_types: new Dictionary().setUrl('/api/svc/nsi/knm_checklist_types?order_by[id]=asc').loadAll(),
  nsi_knm_checklist_urls: new Dictionary().setUrl('/api/svc/nsi/knm_checklist_urls').setItemUrl('/api/svc/nsi/knm_checklist_url'),
  nsi_knm_checklist_answer_type: new Dictionary().setUrl('/api/svc/nsi/knm_checklist_answer_type').loadAll(),
  nsi_knm_notification_methods: new Dictionary().setUrl('/api/svc/nsi/knm_notification_methods').loadAll(),
  nsi_knm_actions: new Dictionary().setUrl('/api/svc/nsi/knm_actions').loadAll(),
  nsi_knm_approve_doc_types: new Dictionary<IDictionaryItem & {separate_directions_id: number}>().setUrl('/api/svc/nsi/knm_approve_doc_types').loadAll(),
  nsi_reestr_knm_actions: new Dictionary().setUrl('/api/svc/nsi/knm_actions').loadAll(),
  nsi_knm_dl_position: new Dictionary().setUrl('/api/svc/nsi/knm_dl_position').loadAll(),
  nsi_verification_subject_kinds: new Dictionary()
    .setUrl('/api/svc/nsi/verification_subject_kinds')
    .setItemUrl('/api/svc/nsi/verification_subject_kind'),
  knm_hold_enable_reasons: new Dictionary()
    .setUrl('/api/svc/nsi/knm_hold_enable_reasons')
    .setItemUrl('/api/svc/nsi/knm_hold_enable_reason'),
  knd_card_reestr_id: new Dictionary()
    .setUrl('/api/svc/knd/v2/knd_card'),
  knd_card_check_list_id: new Dictionary()
    .setUrl('/api/svc/knd/v2/knd_card_check_list'),
  knd_quotes_card_reestr_id: new Dictionary()
    .setUrl('api/svc/knd/v2/knd_quotas_card'),
  nsi_knm_plan_type: new Dictionary().setUrl('/api/svc/nsi/knm_plan_types').loadAll(),
  nsi_envharm_climatic_conditions: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_climatic_conditions').loadAll(),
  nsi_envharm_ecology_factors: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_ecology_factors').loadAll(),
  nsi_envharm_inflation_rate: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_inflation_rate').loadAll(),
  nsi_envharm_duration_harm_before_elimination: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_duration_harm_before_elimination').loadAll(),
  nsi_envharm_organic_matter_discharge: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_organic_matter_discharge').loadAll(),
  nsi_envharm_inorganic_substances_water: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_inorganic_substances_water').loadAll(),
  nsi_envharm_dumping_pesticides_water: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_dumping_pesticides_water').loadAll(),
  nsi_envharm_discharge_petroleum_products: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_discharge_petroleum_products').loadAll(),
  nsi_envharm_fees_harm_dumping: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_fees_harm_dumping').loadAll(),
  nsi_envharm_intensity_harm: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_intensity_harm').loadAll(),
  nsi_res_errors_types: new Dictionary().setUrl('/api/svc/nsi/res_error_types').loadAll(),
  nsi_ovnu_errors_types: new Dictionary().setUrl('/api/svc/nsi/ovnu_errors_type').loadAll(),
  nsi_envharm_damage_clutter_soils: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_damage_clutter_soils').loadAll(),
  nsi_envharm_soil_damage_fees: new Dictionary().setUrl('/api/svc/nsi/nsi_envharm_soil_damage_fees').loadAll(),
  payment_management_sufd_payments: new Dictionary().setUrl('/api/payments-management/sufd/payments'),

  excluded_options: new Dictionary(StaticDictValues.excluded_options),
  secret_registry: new Dictionary(StaticDictValues.secret_registry),
  profile_item_acceptable: new Dictionary(StaticDictValues.profile_item_acceptable),
  document_registry_status: new Dictionary(StaticDictValues.document_registry_status),
  document_registry_system_types: new Dictionary(StaticDictValues.document_registry_system_types),
  erul_status: new Dictionary(StaticDictValues.erul_status),
  source: new Dictionary(StaticDictValues.source),
  datasource_operators: new Dictionary(StaticDictValues.datasource_operators),
  onvos_is_secret: new Dictionary(StaticDictValues.onvos_is_secret),
  datasource: new Dictionary(StaticDictValues.datasource),
  monitoring_chart_time: new Dictionary(StaticDictValues.monitoring_chart_time),
  sensors_with_monitoring: new Dictionary(null),
  stationary_sources_with_monitoring: new Dictionary(null),
  sensors_with_monitoring_prediction: new Dictionary(null),
  stationary_sources_with_monitoring_prediction: new Dictionary(null),
  all_sources_for_onv: new Dictionary(null),
  smev_mode: new Dictionary(StaticDictValues.smev_mode),
  smev_isPril: new Dictionary(StaticDictValues.smev_isPril),
  typePerson: new Dictionary(StaticDictValues.typePerson),
  citizenshipType: new Dictionary(StaticDictValues.citizenshipType),
  genderType: new Dictionary(StaticDictValues.genderType),
  operating_mode: new Dictionary(StaticDictValues.operating_mode),
  genderTypeEng: new Dictionary(StaticDictValues.genderTypeEng),
  identityType: new Dictionary(StaticDictValues.identityType),
  active_status: new Dictionary(StaticDictValues.active_status),
  outgoing_message_status: new Dictionary(StaticDictValues.outgoing_message_status),
  reports_filters_status_code: new Dictionary(StaticDictValues.reports_filters_status_code),
  analytic_filters_status_code: new Dictionary(StaticDictValues.analytic_filters_status_code),
  smev_licence_fns_processing_code: new Dictionary(StaticDictValues.smev_licence_fns_processing_code),
  pollutant_hazard_class: new Dictionary(StaticDictValues.pollutant_hazard_class),
  eek_kinds: new Dictionary(StaticDictValues.eek_kinds),
  eek_duplicate_reasons: new Dictionary(StaticDictValues.eek_duplicate_reasons),
  ker_application_type: new Dictionary(StaticDictValues.ker_application_type),
  revise_params_option: new Dictionary(StaticDictValues.revise_params_option),
  ams_state: new Dictionary(StaticDictValues.ams_state),
  seal_state: new Dictionary(StaticDictValues.seal_state),
  refund_conclusion: new Dictionary(StaticDictValues.refund_conclusion),
  refund_budget: new Dictionary(StaticDictValues.refund_budget),
  doc_public_expertises: new Dictionary(StaticDictValues.doc_public_expertises),
  report_type_onvos: new Dictionary(StaticDictValues.report_type_onvos),
  payment_status: new Dictionary(StaticDictValues.payment_status),
  payment_status_filter_table: new Dictionary(StaticDictValues.payment_status_filter_table),
  payments_management_state_services: new Dictionary(StaticDictValues.payments_management_state_services),
  payments_management_state_services_dpnvos: new Dictionary(StaticDictValues.payments_management_state_services_dpnvos),
  payments_management_state_services_eco: new Dictionary(StaticDictValues.payments_management_state_services_eco),
  payments_management_state_services_other_documents: new Dictionary(StaticDictValues.payments_management_state_services_other_documents),
  pm_state_services: new Dictionary(StaticDictValues.pm_state_services),
  payments_management_analytics_report_statuses: new Dictionary(StaticDictValues.payments_management_analytics_report_statuses),
  payments_management_analytics_form_types: new Dictionary(StaticDictValues.payments_management_analytics_form_types),
  pm_analytics_form_environment_component_name: new Dictionary(StaticDictValues.pm_analytics_form_environment_component_name),
  payments_management_payer_type: new Dictionary(StaticDictValues.payments_management_payer_type),
  payments_management_payer_document_code: new Dictionary(StaticDictValues.payments_management_payer_document_code),
  pm_opening_balance_imports_parse_status: new Dictionary(StaticDictValues.pm_opening_balance_imports_parse_status),
  payments_management_sufd_imports_parse_status: new Dictionary(StaticDictValues.payments_management_sufd_imports_parse_status),
  payments_management_sufd_imports_extract_kind: new Dictionary(StaticDictValues.payments_management_sufd_imports_extract_kind),
  payments_management_sufd_imports_budget_level: new Dictionary(StaticDictValues.payments_management_sufd_imports_budget_level),
  payments_management_sufd_imports_sufd_type: new Dictionary(StaticDictValues.payments_management_sufd_imports_sufd_type),
  payments_management_vt_operations_uf_type: new Dictionary(StaticDictValues.payments_management_vt_operations_uf_type),
  pm_vt_operations_doc_code: new Dictionary(StaticDictValues.pm_vt_operations_doc_code),
  pm_vt_operations_doc_code_filters: new Dictionary(StaticDictValues.pm_vt_operations_doc_code_filters),
  payments_management_vt_operations_loaded: new Dictionary(StaticDictValues.payments_management_vt_operations_loaded),
  reconciliation_status_filter: new Dictionary(StaticDictValues.reconciliation_status_filter),
  reconciliation_status_with_null_filter: new Dictionary(StaticDictValues.reconciliation_status_with_null_filter),
  reconciliation_status: new Dictionary(StaticDictValues.reconciliation_status),
  applicant_types: new Dictionary(StaticDictValues.applicant_types),
  all_applicant_types: new Dictionary(StaticDictValues.all_applicant_types),
  applicant_types_with_absent: new Dictionary(StaticDictValues.applicant_types_with_absent),
  all_applicant_types_with_absent: new Dictionary(StaticDictValues.all_applicant_types_with_absent),
  cites_okei_groups: new Dictionary(StaticDictValues.cites_okei_groups),
  gee_document_status: new Dictionary(StaticDictValues.gee_document_status),
  dep_lic_requisite_types: new Dictionary(StaticDictValues.dep_lic_requisite_types),
  controlled_entity_statuses: new Dictionary(StaticDictValues.controlled_entity_statuses),
  charges_lk_years: new Dictionary(getYearsForDictionary(2000, null, true)),
  payments_years: new Dictionary(getYearsForDictionary(2000, null, true)),
  year_plus_3_years: new Dictionary(getYearsForDictionary(2000, null, true, 3)),
  payments_act_years: new Dictionary(getYearsForDictionary(2020, null, true)),
  years_till_2019: new Dictionary(getYearsForDictionary(2000, 2019, true)),
  payments_years_analytics_form_6: new Dictionary(getYearsForDictionary(2021, 2031)),
  self_exam_report_years: new Dictionary(getYearsForDictionary(2019, 2024, true)),
  payments_analytics_form_6_periods: new Dictionary(StaticDictValues.payments_analytics_form_6_periods),
  payments_management_xml_eb_type: new Dictionary(StaticDictValues.payments_management_xml_eb_type),
  pm_ksv_declaration_statuses: new Dictionary(StaticDictValues.pm_ksv_declaration_statuses),
  payments_management_declarations_zero: new Dictionary(StaticDictValues.payments_management_declarations_zero),
  payments_management_accruals_canceled: new Dictionary(StaticDictValues.payments_management_accruals_canceled),
  payments_management_report_parameters: new Dictionary(StaticDictValues.payments_management_report_parameters),
  nsi_payment_management_rpn_kbk: new Dictionary()
    .setUrl('/api/svc/nsi/verification_subject_kinds')
    .setItemUrl('/api/svc/nsi/verification_subject_kind'),
  supervision_levels: new Dictionary(StaticDictValues.supervision_levels),
  registry_levels: new Dictionary(StaticDictValues.registry_levels),
  monitoring_source_types: new Dictionary(StaticDictValues.monitoring_source_types),
  VidResh: new Dictionary(StaticDictValues.VidResh),
  PrDeistv: new Dictionary(StaticDictValues.PrDeistv),
  slvd: new Dictionary(StaticDictValues.slvd),
  payerType: new Dictionary(StaticDictValues.payerType),
  smev_statuses: new Dictionary(StaticDictValues.smev_statuses),
  has_accruals: new Dictionary(StaticDictValues.has_accruals),
  is_systemic: new Dictionary(StaticDictValues.is_systemic),
  is_manual: new Dictionary(StaticDictValues.is_manual),
  yes_no: new Dictionary(StaticDictValues.yes_no),
  no_oro_export: new Dictionary(StaticDictValues.no_oro_export),
  received_transmitted: new Dictionary(StaticDictValues.received_transmitted),
  approve_woman: new Dictionary(StaticDictValues.approve_woman),
  yes_no_digit: new Dictionary(StaticDictValues.yes_no_digit),
  yes_no_null: new Dictionary(StaticDictValues.yes_no_null),
  gisgmp_form_type: new Dictionary(StaticDictValues.gisgmp_form_type),
  yes_no_miss: new Dictionary(StaticDictValues.yes_no_miss),
  yes_no_not_apply: new Dictionary(StaticDictValues.yes_no_not_apply),
  yes_no_all: new Dictionary(StaticDictValues.yes_no_all),
  declaration_status_done: new Dictionary(StaticDictValues.declaration_status_done),
  is_present: new Dictionary(StaticDictValues.is_present),
  is_exist: new Dictionary(StaticDictValues.is_exist),
  is_non_resident: new Dictionary(StaticDictValues.is_non_resident),
  is_resident: new Dictionary(StaticDictValues.is_resident),
  is_xml_uploaded_to_eb: new Dictionary(StaticDictValues.is_xml_uploaded_to_eb),
  is_xml_rec_uploaded_to_eb: new Dictionary(StaticDictValues.is_xml_rec_uploaded_to_eb),
  is_excluded: new Dictionary(StaticDictValues.is_excluded),
  only_excluded_map: new Dictionary(StaticDictValues.only_excluded_map),
  template_status: new Dictionary(StaticDictValues.template_status),
  smev4_status: new Dictionary(StaticDictValues.smev4_status),
  is_personally: new Dictionary(StaticDictValues.is_personally),
  metting_place: new Dictionary(StaticDictValues.metting_place),
  registry_category: new Dictionary(StaticDictValues.registry_category),
  tool_environment_type: new Dictionary(StaticDictValues.tool_environment_type),
  onv_application_method_id: new Dictionary(StaticDictValues.onv_application_method_id),
  onv_application_method_id_lk: new Dictionary(StaticDictValues.onv_application_method_id_lk),
  onv_emission_object_types: new Dictionary(StaticDictValues.onv_emission_object_types),
  onv_emission_object_type_static: new Dictionary(StaticDictValues.onv_emission_object_type_static),
  onv_emission_object_types_new: new Dictionary(StaticDictValues.onv_emission_object_types_new),
  onv_emission_object_type_static_new: new Dictionary(StaticDictValues.onv_emission_object_type_static_new),
  onv_measure_tools_id: new Dictionary(StaticDictValues.onv_measure_tools_id),
  onv_neutralization_tools_id: new Dictionary(StaticDictValues.onv_neutralization_tools_id),
  onv_exclusion_reason: new Dictionary().setUrl('/api/svc/nsi/onvos-exclusion-reasons'),
  onv_exclusion_reason_all: new Dictionary().setUrl('/api/svc/nsi/onvos-exclusion-reasons?filter[is_active]=notnull'),
  groro_exclusion_reason: new Dictionary(StaticDictValues.groro_exclusion_reason),
  event_groups: new Dictionary(StaticDictValues.event_groups),
  report_year: new Dictionary(StaticDictValues.report_year),
  okpd_tnved: new Dictionary(StaticDictValues.okpd_tnved),
  calculation_method_type: new Dictionary(StaticDictValues.calculation_method_type),
  license_request_old_statuses: new Dictionary(StaticDictValues.license_request_old_statuses),
  time_of_reporting: new Dictionary(StaticDictValues.time_of_reporting),
  danger_class: new Dictionary(StaticDictValues.danger_class),
  doc_type: new Dictionary(StaticDictValues.doc_type),
  report_doc_type: new Dictionary(StaticDictValues.report_doc_type),
  filter_doc_type: new Dictionary(StaticDictValues.filter_doc_type),
  cites_certificate_import_permission_status: new Dictionary(StaticDictValues.cites_certificate_import_permission_status),
  cites_certificate_import_permit_results: new Dictionary(StaticDictValues.cites_certificate_import_permit_results),
  fgen_checked_org: new Dictionary([]),
  attestation_number: new Dictionary(StaticDictValues.attestation_number),
  emission_source_types_static: new Dictionary(StaticDictValues.emission_source_types_static),
  months: new Dictionary(StaticDictValues.months),
  payment_management_based_on_types: new Dictionary(StaticDictValues.payment_management_based_on_types),
  payment_management_accruals_based_on: new Dictionary(StaticDictValues.payment_management_accruals_based_on),
  payment_management_accruals_based_on_view: new Dictionary(StaticDictValues.payment_management_accruals_based_on_view),
  payment_management_accruals: new Dictionary()
    .setUrl('/api/svc/nsi/knd_inspection_address_types')
    .setItemUrl('/api/svc/nsi/knd_inspection_address_types'),
  payments_management_formation_method: new Dictionary(StaticDictValues.payments_management_formation_method),
  payments_management_based_on_gis_gmp: new Dictionary(StaticDictValues.payments_management_based_on_gis_gmp),
  payment_management_quarters: new Dictionary(StaticDictValues.payment_management_quarters),
  payment_management_quarters_for_form: new Dictionary(StaticDictValues.payment_management_quarters_for_form),
  payment_management_quarters_with_null: new Dictionary(StaticDictValues.payment_management_quarters_with_null),
  payment_management_gis_gmp_statuses: new Dictionary(StaticDictValues.payment_management_gis_gmp_statuses),
  payment_management_gis_gmp_request_types: new Dictionary(StaticDictValues.payment_management_gis_gmp_request_types),
  sender_role: new Dictionary(StaticDictValues.sender_role),
  pollutants_markers: new Dictionary(StaticDictValues.pollutants_markers),
  report_type: new Dictionary(StaticDictValues.report_type),
  reason_names_search_types: new Dictionary(StaticDictValues.reason_names_search_types),
  payments_management_gis_gmp_request_status: new Dictionary(StaticDictValues.payments_management_gis_gmp_request_status),
  payments_management_gis_gmp_rrs_vs_status: new Dictionary(StaticDictValues.payments_management_gis_gmp_rrs_vs_status),
  payments_management_gis_gmp_sac_meaning_status: new Dictionary(StaticDictValues.payments_management_gis_gmp_sac_meaning_status),
  payments_management_reestr: new Dictionary(StaticDictValues.payments_management_reestr),
  payments_management_collection_way: new Dictionary(StaticDictValues.payments_management_collection_way),
  payments_management_collection_status: new Dictionary(StaticDictValues.payments_management_collection_status),
  renewal_number: new Dictionary(StaticDictValues.renewal_number),
  groro_request_type: new Dictionary(StaticDictValues.groro_request_type),
  adjust_reasons: new Dictionary(StaticDictValues.adjust_reasons),
  smev3_process_status: new Dictionary(StaticDictValues.smev3_process_status),
  smev_registry_mchs_units_reject: new Dictionary(StaticDictValues.smev_registry_mchs_units_reject),
  pm_reports_lk_state_service_ids: new Dictionary(StaticDictValues.pm_reports_lk_state_service_ids),
  ervk_status: new Dictionary(StaticDictValues.ervk_status),
  npa_number: new Dictionary(StaticDictValues.npa_number),
  approval_type: new Dictionary(StaticDictValues.approval_type),
  elk_statuses: new Dictionary(StaticDictValues.elk_statuses),
  available_sign_users: new Dictionary(),
  environment_taxable_goods_by_report_year: new Dictionary(),
  process_control_act_errors_oktmo: new Dictionary(),
  process_control_act_errors_onvos: new Dictionary(),
  registry_inn: new Dictionary(),
  license_activity_waste_place_ids: new Dictionary(),
  fgen_object_control_id: new Dictionary(),
  fgn_object_id: new Dictionary(),
  dpnvos_pek_onvos: new Dictionary(),
  dpnvos_dvos_onvos: new Dictionary(),
  dpnvos_groro_onvos: new Dictionary(),
  inquiry_kinds_templates: new Dictionary<SmevInquiryKindTemplate>(),
};

export type DictKeysType = {
  [key in keyof typeof DICT]: key;
};

export const DictKEYS: DictKeysType = Object.keys(DICT).reduce((obj, key) => {
  obj[key] = key;
  return obj;
}, {} as DictKeysType);

export type DictDadataKeysType = {
  [key in keyof typeof DICT_DADATA]: key;
};

export const DictDadataKEYS: DictDadataKeysType = Object.keys(DICT_DADATA).reduce((obj, key) => {
  obj[key] = key;
  return obj;
}, {} as DictDadataKeysType);
