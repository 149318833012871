import { TableType } from 'src/app/providers/_enum';

export enum ReestrTableTypeString {
  'fgen-recult' = 'fgen-recult',
  'fgen-object' = 'fgen-object',
  'fggn-object' = 'fggn-object',
  'fgzn-object' = 'fgzn-object',
  'fgohn-object' = 'fgohn-object',
  'fgotn-object' = 'fgotn-object',
  'fglesn-object' = 'fglesn-object',
  'fgan-object' = 'fgan-object',
  'fgahn-object' = 'fgahn-object',
  'ancr-object' = 'ancr-object',
  'eroi-object' = 'eroi-object',
  'atex-object' = 'atex-object',
  'fkko-object' = 'fkko-object',
  'animals-object' = 'animals-object',
  'animal-registry' = 'animal-registry',
  'knm-object' = 'knm-object',
  'fgn-category-change' = 'fgn-category-change',

  'knd-quotas-card' = 'knd-quotas-card',
  'knd-quotas-program' = 'knd-quotas-program',

  'pm_visit_card' = 'pm_visit_card',
  'pm_warning_card' = 'pm_warning_card',

  'registry-permission' = 'registry-permission',
  'commission-object' = 'commission-object',
  'liquidated-object' = 'liquidated-object',
  'cites-object' = 'cites-object',
  'recycler-inclusion' = 'recycler-inclusion',
  'recycler-actualization' = 'recycler-actualization',

  'control-objects-by-indicators' = 'control-objects-by-indicators',

  'expertise-request-clati' = 'expertise-request-clati',
  'key-metric' = 'key-metric',
  'pre-trial-appeal' = 'pre-trial-appeal',

  'administrative-matter' = 'administrative-matter',
  'administrative-matters-submission' = 'administrative-matters-submission',
  'administrative-matters-order' = 'administrative-matters-order',
}

export enum ReestrTableType {
  fgen_recult = 'fgen_recult',
  fgen_object = 'fgen_object',
  fggn_object = 'fggn_object',
  fgzn_object = 'fgzn_object',
  fgohn_object = 'fgohn_object',
  fgotn_object = 'fgotn_object',
  fglesn_object = 'fglesn_object',
  fgan_object = 'fgan_object',
  fgahn_object = 'fgahn_object',
  ancr_object = 'ancr_object',
  eroi_object = 'eroi_object',
  public_inspector_request = 'public_inspector_request',
  atex_object = 'atex_object',
  fkko_object = 'fkko_object',
  animals_object = 'animals_object',
  animal_registry = 'animals_reestr_record',
  knm_object = 'knm_object',
  fgn_category_change = 'fgn_category_change',

  knd_quotas_card = 'knd_quotas_card',
  knd_quotas_program = 'knd_quotas_program',

  pm_visit_card = 'pm_visit_card',
  pm_warning_card = 'pm_warning_card',

  registry_permission = 'registry_permission',
  commission_object = 'commission_object',
  liquidated_object = 'liquidated_object',
  cites_object = 'cites_object',
  recycler_inclusion = 'recycler_inclusion',
  recycler_actualization = 'recycler_actualization',

  addcharge = 'addcharge',
  appointment = 'appointment',

  dpnvos_requests = 'dpnvos_requests',
  dpnvos_clarification = 'dpnvos_clarification',
  dpnvos_actual_expenses = 'dpnvos_actual_expenses',
  dpnvos_control_act = 'dpnvos_control_act',
  ecocharge_registry = 'ecocharge_registry',
  utilization_registry = 'utilization_registry',
  ecocharge_control_act = 'ecocharge_control_act',
  utilization_control_act = 'utilization_control_act',
  expertise_request = 'expertise_request',
  expense_reimbursement = 'expense_reimbursement',
  termination_request = 'termination_request',
  rosnedra_notice = 'rosnedra_notice',
  ecocharge_request = 'ecocharge_request',
  utilization_request = 'utilization_request',
  control_objects_by_indicator = 'control_objects_by_indicator',
  key_metric = 'key_metric',
  pre_trial_appeal = 'pre_trial_appeal',
  expertise_request_clati = 'expertise_request_clati',

  administrative_matter = 'administrative_matter',
  administrative_matter_order = 'administrative_matter_order',
  matters_submission = 'matters_submission',
}

export const TableTypeMap: { [key in ReestrTableTypeString]: ReestrTableType } = {
  [ReestrTableTypeString['fgen-recult']]: ReestrTableType.fgen_recult,

  [ReestrTableTypeString['fgen-object']]: ReestrTableType.fgen_object,
  [ReestrTableTypeString['fggn-object']]: ReestrTableType.fggn_object,
  [ReestrTableTypeString['fgzn-object']]: ReestrTableType.fgzn_object,
  [ReestrTableTypeString['fgohn-object']]: ReestrTableType.fgohn_object,
  [ReestrTableTypeString['fgotn-object']]: ReestrTableType.fgotn_object,
  [ReestrTableTypeString['fglesn-object']]: ReestrTableType.fglesn_object,
  [ReestrTableTypeString['fgan-object']]: ReestrTableType.fgan_object,
  [ReestrTableTypeString['fgahn-object']]: ReestrTableType.fgahn_object,
  [ReestrTableTypeString['ancr-object']]: ReestrTableType.ancr_object,
  [ReestrTableTypeString['eroi-object']]: ReestrTableType.eroi_object,
  [ReestrTableTypeString['atex-object']]: ReestrTableType.atex_object,
  [ReestrTableTypeString['fkko-object']]: ReestrTableType.fkko_object,
  [ReestrTableTypeString['animals-object']]: ReestrTableType.animals_object,
  [ReestrTableTypeString['animal-registry']]: ReestrTableType.animal_registry,
  [ReestrTableTypeString['knm-object']]: ReestrTableType.knm_object,
  [ReestrTableTypeString['fgn-category-change']]: ReestrTableType.fgn_category_change,

  [ReestrTableTypeString['knd-quotas-card']]: ReestrTableType.knd_quotas_card,
  [ReestrTableTypeString['knd-quotas-program']]: ReestrTableType.knd_quotas_program,

  [ReestrTableTypeString['pm_visit_card']]: ReestrTableType.pm_visit_card,
  [ReestrTableTypeString['pm_warning_card']]: ReestrTableType.pm_warning_card,

  [ReestrTableTypeString['registry-permission']]: ReestrTableType.registry_permission,
  [ReestrTableTypeString['commission-object']]: ReestrTableType.commission_object,
  [ReestrTableTypeString['liquidated-object']]: ReestrTableType.liquidated_object,
  [ReestrTableTypeString['cites-object']]: ReestrTableType.cites_object,
  [ReestrTableTypeString['recycler-inclusion']]: ReestrTableType.recycler_inclusion,
  [ReestrTableTypeString['recycler-actualization']]: ReestrTableType.recycler_actualization,
  [ReestrTableTypeString['appointment']]: ReestrTableType.appointment,

  [ReestrTableTypeString['control-objects-by-indicators']]: ReestrTableType.control_objects_by_indicator,
  [ReestrTableTypeString['expertise-request-clati']]: ReestrTableType.expertise_request_clati,
  [ReestrTableTypeString['key-metric']]: ReestrTableType.key_metric,
  [ReestrTableTypeString['pre-trial-appeal']]: ReestrTableType.pre_trial_appeal,

  [ReestrTableTypeString['administrative-matter']]: ReestrTableType.administrative_matter,
  [ReestrTableTypeString['administrative-matters-order']]: ReestrTableType.administrative_matter_order,
  [ReestrTableTypeString['administrative-matters-submission']]: ReestrTableType.matters_submission,
};

export const StringToTableTypeMap = Object.keys(TableTypeMap).reduce((acc, key) => {
  acc[TableTypeMap[key]] = key;
  return acc;
}, {} as { [key in ReestrTableType]: ReestrTableTypeString } );


export const CreateButtonNameMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: 'Новый объект',
  [TableType.fgen_object]: 'Новый объект',
  [TableType.fggn_object]: 'Новый объект',
  [TableType.fgzn_object]: 'Новый объект',
  [TableType.fgohn_object]: 'Новый объект',
  [TableType.fgotn_object]: 'Новый объект',
  [TableType.fglesn_object]: 'Новый объект',
  [TableType.fgan_object]: 'Новый объект',
  [TableType.fgahn_object]: 'Новый объект',
  [TableType.ancr_object]: 'Новый объект',
  [TableType.eroi_object]: 'Новый объект',
  [TableType.recycler_inclusion]: 'Новый объект',
  [TableType.recycler_actualization]: 'Новый объект',
  [TableType.public_inspector_request]: 'Новый объект',
  [TableType.atex_object]: 'Новый объект',
  [TableType.fkko_object]: 'Новый объект',
  [TableType.animals_object]: 'Новое животное',
  [TableType.animal_registry]: 'Новое животное',
  [TableType.knm_object]: 'Новый объект',
  [TableType.fgn_category_change]: 'Новое заявление',

  [TableType.knd_quotas_card]: 'Создать паспорт проверок',
  [TableType.knd_quotas_program]: 'Создать программу проверок',

  [TableType.pm_visit_card]: 'Создать',
  [TableType.pm_warning_card]: 'Создать',

  [TableType.registry_permission]: 'Новый объект',
  [TableType.commission_object]: 'Новый объект',
  [TableType.liquidated_object]: 'Новый объект',
  [TableType.cites_object]: 'Новый объект',

  [TableType.addcharge]: 'Новый объект',
  [TableType.appointment]: 'Запись на прием',

  [TableType.dpnvos_requests]: 'Новый объект',
  [TableType.dpnvos_clarification]: 'Новый объект',
  [TableType.dpnvos_actual_expenses]: 'Новый объект',
  [TableType.dpnvos_control_act]: 'Новый объект',
  [TableType.ecocharge_registry]: 'Новый объект',
  [TableType.utilization_registry]: 'Новый объект',
  [TableType.ecocharge_control_act]: 'Новый объект',
  [TableType.utilization_control_act]: 'Новый объект',
  [TableType.expertise_request]: 'Новый объект',
  [TableType.expense_reimbursement]: 'Новый объект',
  [TableType.termination_request]: 'Новый объект',
  [TableType.rosnedra_notice]: 'Новый объект',
  [TableType.ecocharge_request]: 'Новый объект',
  [TableType.utilization_request]: 'Новый объект',

  [TableType.control_objects_by_indicator]: 'Новый объект',
  [TableType.expertise_request_clati]: 'Новая заявка',
  [TableType.key_metric]: 'Новый показатель',
  [TableType.pre_trial_appeal]: '',

  [TableType.administrative_matter]: 'Создать',
  [TableType.administrative_matter_order]: '',
  [TableType.matters_submission]: '',
};

export const FullNameMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: 'Реестр КНМ',
  [TableType.fgen_object]: 'Перечень объектов контроля, подлежащих федеральному государственному экологическому контролю (надзору)',
  [TableType.fggn_object]: 'Перечень объектов контроля, подлежащих федеральному государственному геологическому контролю (надзору)',
  [TableType.fgzn_object]: 'Перечень объектов контроля, подлежащих федеральному государственному земельному контролю (надзору)',
  [TableType.fgohn_object]: 'Перечень объектов контроля, подлежащих федеральному государственному охотничьему контролю (надзору)',
  [TableType.fgotn_object]:
  'Перечень объектов контроля, подлежащих федеральному государственному контролю в области охраны и ' +
  'использования особо охраняемых природных территорий',
  [TableType.fglesn_object]: 'Перечень объектов контроля, подлежащих федеральному государственному лесному контролю (надзору)',
  [TableType.fgan_object]:
  'Перечень объектов контроля, подлежащих федеральному государственному контролю (надзору) в области ' + 'обращения с животными',
  [TableType.fgahn_object]:
  'Перечень объектов контроля, подлежащих федеральному государственному контролю (надзору ) в области ' +
  'охраны, воспроизводства и использования объектов животного мира и среды их обитания',
  [TableType.ancr_object]: 'Реестр оборота объектов животного мира',
  [TableType.eroi_object]: 'Перечень общественных инспекторов по охране окружающей среды',
  [TableType.recycler_inclusion]: 'Заявления на включение в реестр утилизаторов',
  [TableType.recycler_actualization]: 'Заявление/уведомление об актуализации реестра утилизаторов',
  [TableType.public_inspector_request]: 'Реестр общественных инспекторов',
  [TableType.atex_object]: 'Реестр аттестованных экспертов',
  [TableType.fkko_object]: 'Банк данных об отходах',
  [TableType.animals_object]: 'Реестр животных',
  [TableType.animal_registry]: 'Реестр животных',
  [TableType.knm_object]: 'Реестр КНМ',
  [TableType.fgn_category_change]: 'Заявление об изменении категории риска',

  [TableType.knd_quotas_card]: 'Реестр паспортов проверок в отношении квотируемых объектов',
  [TableType.knd_quotas_program]: 'Реестр программ проверок в отношении квотируемых объектов',

  [TableType.pm_visit_card]: 'Реестр профилактических визитов',
  [TableType.pm_warning_card]: 'Реестр предостережений',

  [TableType.registry_permission]: 'Реестр выданных разрешений',
  [TableType.commission_object]: 'Работа с комиссиями',
  [TableType.liquidated_object]: 'Реестр ликвидированных и консервированных объектов',
  [TableType.cites_object]: 'Реестр разрешений СИТЕС (CITES)',

  [TableType.addcharge]: 'Требования о доначислении',
  [TableType.appointment]: 'Записи на прием в ведомство',

  [TableType.dpnvos_requests]: 'Реестр ДПНВОС',
  [TableType.dpnvos_clarification]: 'Реестр требований о предоставлении пояснений или внесения исправлений в декларацию',
  [TableType.dpnvos_actual_expenses]: 'Реестр требований о подтверждении фактически произведенных расходов на мероприятия ' +
  'по снижению воздействия на ОС',
  [TableType.dpnvos_control_act]: 'Реестр актов контроля за исчислением платы за НВОС',
  [TableType.ecocharge_registry]: 'Реестр расчетов экосбора',
  [TableType.utilization_registry]: 'Реестр отчетов о выполнении нормативов утилизации',
  [TableType.ecocharge_control_act]: 'Акты контроля',
  [TableType.utilization_control_act]: 'Акты контроля',
  [TableType.expertise_request]: 'Реестр заявлений на проведение экспертизы',
  [TableType.expense_reimbursement]: 'Реестр заявлений на возмещение расходов, понесенных экспертом в связи с участием в контрольном (надзорном) мероприятии',
  [TableType.termination_request]: 'Реестр заявлений о прекращении аттестации',
  [TableType.rosnedra_notice]: 'Реестр уведомлений о допущенных нарушениях условий пользования недрами по лицензии и возможном досрочном прекращении права пользования недрами',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: 'Объекты, требующие принятия решений на основании индикаторов риска',
  [TableType.expertise_request_clati]: 'Заявка на проведение экспертизы (ЦЛАТИ)',
  [TableType.key_metric]: 'Реестр ключевых показателей по видам контроля',
  [TableType.pre_trial_appeal]: 'Реестр досудебного обжалования',

  [TableType.administrative_matter]: 'Реестр административных и уголовных дел',
  [TableType.administrative_matter_order]: 'Реестр постановлений о назначении административного наказания',
  [TableType.matters_submission]: 'Реестр представлений об устранении причин и условий, способствовавших совершению административного правонарушения',
};


export const ShortNameMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: 'Реестр КНМ',

  [TableType.fgen_object]: 'Реестр объектов ФГЭН',
  [TableType.fggn_object]: 'Реестр объектов ФГГН',
  [TableType.fgzn_object]: 'Реестр объектов ФГЗН',
  [TableType.fgohn_object]: 'Реестр объектов ФГОХН',
  [TableType.fgotn_object]: 'Реестр объектов ФГОТН',
  [TableType.fglesn_object]: 'Реестр объектов ФГЛесН',
  [TableType.fgan_object]: 'Реестр объектов ФГКОЖ',
  [TableType.fgahn_object]: 'Реестр объектов ФГКОЖС',

  [TableType.ancr_object]: 'Реестр оборота животного мира',
  [TableType.eroi_object]: 'Перечень общественных инспекторов по охране окружающей среды',
  [TableType.recycler_inclusion]: 'Заявления на включение в реестр утилизаторов',
  [TableType.recycler_actualization]: 'Заявление/уведомление об актуализации',
  [TableType.public_inspector_request]: 'Реестр общественных инспекторов',
  [TableType.atex_object]: 'Реестр аттестованных экспертов',
  [TableType.fkko_object]: 'Банк данных об отходах',
  [TableType.animals_object]: 'Реестр животных',
  [TableType.animal_registry]: 'Реестр животных',
  [TableType.knm_object]: 'Реестр КНМ',
  [TableType.fgn_category_change]: 'Заявления об изменении категории риска',

  [TableType.knd_quotas_card]: 'Реестр паспортов проверок',
  [TableType.knd_quotas_program]: 'Реестр программ проверок',

  [TableType.pm_visit_card]: 'Реестр профилактических визитов',
  [TableType.pm_warning_card]: 'Реестр предостережений',

  [TableType.registry_permission]: 'Реестр выданных разрешений',
  [TableType.commission_object]: 'Работа с комиссиями',
  [TableType.liquidated_object]: 'Реестр ликвидированных и консервированных объектов',
  [TableType.cites_object]: 'Реестр объектов СИТЕС',

  [TableType.addcharge]: 'Требования о доначислении',
  [TableType.appointment]: 'Мои записи на прием',

  [TableType.dpnvos_requests]: 'Реестр ДПНВОС',
  [TableType.dpnvos_clarification]: 'Требования о пояснении',
  [TableType.dpnvos_actual_expenses]: 'Требования о подтверждении',
  [TableType.dpnvos_control_act]: 'Акты контроля',
  [TableType.ecocharge_registry]: 'Реестр расчетов экосбора',
  [TableType.utilization_registry]: 'Реестр отчетов о выполнении нормативов утилизации',
  [TableType.ecocharge_control_act]: 'Акты контроля',
  [TableType.utilization_control_act]: 'Акты контроля',
  [TableType.expertise_request]: 'Заявления на проведение экспертизы',
  [TableType.expense_reimbursement]: 'Заявления на возмещение расходов',
  [TableType.termination_request]: 'Заявления о прекращении аттестации',
  [TableType.rosnedra_notice]: 'Реестр уведомлений Роснедр',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: 'Объекты, требующие принятия решений на основании индикаторов риска',
  [TableType.expertise_request_clati]: 'Заявка на проведение экспертизы (ЦЛАТИ)',
  [TableType.key_metric]: 'Реестр ключевых показателей по видам контроля',
  [TableType.pre_trial_appeal]: 'Досудебное обжалование',

  [TableType.administrative_matter]: 'Административное дело',
  [TableType.administrative_matter_order]: 'Реестр постановлений',
  [TableType.matters_submission]: 'Реестр представлений',
};

export const FullNameRoivMap: { [key in ReestrTableType]: string } = {
  ...FullNameMap,
  ...{
    [TableType.fgen_object]: 'Перечень объектов контроля, подлежащих региональному государственному экологическому контролю (надзору)',
    [TableType.fggn_object]: 'Перечень объектов контроля, подлежащих региональному государственному геологическому контролю (надзору)',
    [TableType.fgzn_object]: 'Перечень объектов контроля, подлежащих региональному государственному земельному контролю (надзору)',
    [TableType.fgohn_object]: 'Перечень объектов контроля, подлежащих региональному государственному охотничьему контролю (надзору)',
    [TableType.fgotn_object]:
      'Перечень объектов контроля, подлежащих региональному государственному контролю в области охраны и ' +
      'использования особо охраняемых природных территорий',
    [TableType.fglesn_object]: 'Перечень объектов контроля, подлежащих региональному государственному лесному контролю (надзору)',
    [TableType.fgan_object]:
      'Перечень объектов контроля, подлежащих региональному государственному контролю (надзору) в области ' + 'обращения с животными',
    [TableType.fgahn_object]:
      'Перечень объектов контроля, подлежащих региональному государственному контролю (надзору ) в области ' +
      'охраны, воспроизводства и использования объектов животного мира и среды их обитания',
  },
};

export const ShortNameRoivMap: { [key: string]: string } = {
  ...ShortNameMap,
  ...{
    [TableType.fgen_object]: 'Реестр объектов РГЭН',
    [TableType.fggn_object]: 'Реестр объектов РГГН',
    [TableType.fgzn_object]: 'Реестр объектов РГЗН',
    [TableType.fgohn_object]: 'Реестр объектов РГОХН',
    [TableType.fgotn_object]: 'Реестр объектов РГОТН',
    [TableType.fglesn_object]: 'Реестр объектов РГЛесН',
    [TableType.fgan_object]: 'Реестр объектов РГКОЖ',
    [TableType.fgahn_object]: 'Реестр объектов РГКОЖС',
  },
};

export const RelationMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]:
    'state_service,status,applicant,applicant_information,territory_org,region,' +
    'object_information.fgen_type,object_information.construction_object,check_program,contract_executor_data',

  [TableType.fgen_object]: 'object_information.object_control_type',
  [TableType.fggn_object]: 'object_information.object_control_type,object_information.mineral_resource,object_information.licenses',
  [TableType.fgzn_object]: 'object_information.object_control_type',
  [TableType.fgohn_object]: 'object_information.object_control_type',
  [TableType.fgotn_object]: 'object_information.object_control_type',
  [TableType.fglesn_object]: 'object_information.object_control_type',
  [TableType.fgan_object]: 'object_information.object_control_type,object_information.animals_data',
  [TableType.fgahn_object]: 'object_information.object_control_type,object_information.animals_data',

  [TableType.ancr_object]:
    'status,territory_org,region,applicant_information,animal_object_data.animal_kind,' +
    'animal_object_data.animal_use_target,animal_circulation_owner',
  [TableType.eroi_object]:
    'status,territory_org,region,applicant_information,all_certificate,' +
    'certificate_information.territory_org,certificate_information_prolong1.territory_org,' +
    'certificate_information_prolong2.territory_org,certificate_information_prolong3.territory_org,registration_level,' +
    'certificate_information.testing_territory_org',
  [TableType.recycler_inclusion]:
    'status,territory_org,region,license.license_status,' +
    'object_information.equipments.wastes.fkko,' +
    'object_information.equipments.secondary_raw_materials.secondary_raw_materials_for_utilization,' +
    'object_information.equipments.fkkos.okpd,' +
    'consideration,applicant_information',
  [TableType.recycler_actualization]:
    'status,territory_org,region,license.license_status,' +
    'object_information.equipments.wastes.fkko,' +
    'object_information.equipments.secondary_raw_materials.secondary_raw_materials_for_utilization,' +
    'object_information.equipments.fkkos.okpd,' +
    'consideration,applicant_information,request_type.request_type',
  [TableType.public_inspector_request]: '',
  [TableType.atex_object]:
    'status,territory_org,region,applicant_information,attestation_information.territory_org,' +
    'attestation_information.fgen_expertise_type,attestation_information.reestr_control_type',
  [TableType.fkko_object]: 'status,territory_org,region,base_data,additional_data',
  [TableType.animals_object]: 'status,territory_org,region',
  [TableType.animal_registry]:
    'state_service,status,applicant,applicant_information,territory_org,region,' +
    'statement.animal_data.animal_kind,statement.animal_data.risk_category',
  [TableType.knm_object]: 'status,territory_org,region',
  [TableType.fgn_category_change]: 'status,territory_org,region,object_information,applicant_information',

  [TableType.knd_quotas_card]: 'status,territory_org,common,doer,fgn_objects,applicant_information',
  [TableType.knd_quotas_program]: 'status,territory_org,cards,common,applicant_information',

  [TableType.pm_visit_card]: 'status,territory_org,region,applicant_information,fgn_object,common.reestr_control_type,reject',
  [TableType.pm_warning_card]: 'status,territory_org,region,applicant_information,fgn_object,warning,notification,reject.result',

  [TableType.registry_permission]: 'status,territory_org,region',
  [TableType.commission_object]: 'status,territory_org,region,info.commission_type,info.members,protocols.results',
  [TableType.liquidated_object]: 'status,territory_org,region',
  [TableType.cites_object]: 'status,territory_org,region',

  [TableType.dpnvos_requests]: 'territory_org,region,status,applicant_information,process.actual_expenses_request,' +
    'process.control_act.add_sum,add_charge',
  [TableType.dpnvos_clarification]: 'service_request.status,service_request.applicant_information,errors,' +
    'service_request.region,service_request.territory_org,errors_form,necessary_action,modified_request,' +
    'dpnvos_demand_status',
  [TableType.dpnvos_actual_expenses]: 'service_request.status,service_request.applicant_information,errors,' +
    'service_request.region,service_request.territory_org,errors_form,dpnvos_demand_status,errors_form.r',
  [TableType.dpnvos_control_act]: 'service_request.status,service_request.applicant_information,errors,' +
    'service_request.region,service_request.territory_org,to_user,errors.conclusion_type,dpnvos_act_status,errors.r',
  [TableType.addcharge]: 'territory_org,region,status,applicant_information,status,request.head_user,request.inspector_user',
  [TableType.appointment]: 'territory_org,region',

  [TableType.ecocharge_registry]: 'territory_org,status,region,applicant_information',
  [TableType.utilization_registry]: 'territory_org,status,region,applicant_information',
  [TableType.ecocharge_control_act]: 'service_request.territory_org,service_request.status,service_request.region,' +
    'service_request.applicant_information,to_user,parent_form.errors,parent_form.errors.error_type',
  [TableType.utilization_control_act]: 'service_request.territory_org,service_request.status,service_request.region,' +
    'service_request.applicant_information,to_user,parent_form.errors,parent_form.errors.error_type',
  [TableType.expertise_request]: 'status,territory_org,expertise_info',
  [TableType.expense_reimbursement]: 'status,territory_org,expert_info',
  [TableType.termination_request]: 'status,territory_org,expert_info,termination_of_service_info',
  [TableType.rosnedra_notice]: 'status,territory_org,region,applicant_information,commission,licenses.violations',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: 'status,state_service,territory_org,common.reestr_control_type,common.risk_indicator,common.action_user,common.object.state_service',
  [TableType.expertise_request_clati]: 'status,state_service,territory_org,common.expertise_type,common.department',
  [TableType.key_metric]: 'status,state_service,territory_org,parameters.reestr_control_type',
  [TableType.pre_trial_appeal]: 'status,state_service,territory_org,applicant_information,appeal_applicant,appeal',

  [TableType.administrative_matter]: 'status,state_service,doer,territory_org,region,applicant_information,object_check,offense,sentencing.representations_reasons,sentencing.punishments',

  [TableType.administrative_matter_order]: 'status,territory_org,region,applicant_information,offense.reestr_control_type,info_sending,' +
    'description.koap_article,description.accrual,info_postponement,info_performance.administrative_protocol_result,object_check,appeal_decision',
  [TableType.matters_submission]: 'status,territory_org,region,applicant_information,offense.reestr_control_type,info_sending,object_check,appeal_decisions.decision_type',
};

export const StateServiceIdMap: { [key in ReestrTableType]: number } = {
  [TableType.fgen_recult]: 190,

  [TableType.fgen_object]: 191,
  [TableType.fggn_object]: 192,
  [TableType.fgzn_object]: 193,
  [TableType.fgohn_object]: 195,
  [TableType.fgotn_object]: 196,
  [TableType.fglesn_object]: 197,
  [TableType.fgan_object]: 194,
  [TableType.fgahn_object]: 198,

  [TableType.ancr_object]: 199,
  [TableType.eroi_object]: 230,
  [TableType.recycler_inclusion]: 241,
  [TableType.recycler_actualization]: 242,
  [TableType.public_inspector_request]: 96,
  [TableType.atex_object]: 231,
  [TableType.fkko_object]: 232,
  [TableType.animals_object]: null,
  [TableType.animal_registry]: 170,
  [TableType.knm_object]: 190,
  [TableType.fgn_category_change]: 190,

  [TableType.knd_quotas_card]: 560,
  [TableType.knd_quotas_program]: 559,

  [TableType.pm_visit_card]: 564,
  [TableType.pm_warning_card]: 561,

  [TableType.registry_permission]: null,
  [TableType.commission_object]: 233,
  [TableType.liquidated_object]: -100, // constructor (only matview)
  [TableType.cites_object]: 104,

  [TableType.addcharge]: 615,
  [TableType.appointment]: null,

  [TableType.dpnvos_requests]: null, // '61,72,73,74,610,611',
  [TableType.dpnvos_clarification]: null, // '610,611',
  [TableType.dpnvos_actual_expenses]: null, // '610,611',
  [TableType.dpnvos_control_act]: null, // '610,611',

  [TableType.ecocharge_registry]: 59,
  [TableType.utilization_registry]: 58,
  [TableType.ecocharge_control_act]: 59,
  [TableType.utilization_control_act]: 58,
  [TableType.expertise_request]: 244,
  [TableType.expense_reimbursement]: 245,
  [TableType.termination_request]: 246,
  [TableType.rosnedra_notice]: 243,
  [TableType.ecocharge_request]: 59,
  [TableType.utilization_request]: 58,

  [TableType.control_objects_by_indicator]: 565,
  [TableType.expertise_request_clati]: 563,
  [TableType.key_metric]: 567,
  [TableType.pre_trial_appeal]: 573,

  [TableType.administrative_matter]: 568,
  [TableType.matters_submission]: 569,
  [TableType.administrative_matter_order]: 570,
};

export const RegistryTypeMap: { [key in ReestrTableType]: number } = {
  [TableType.fgen_recult]: null,

  [TableType.fgen_object]: 1,
  [TableType.fggn_object]: 3,
  [TableType.fgzn_object]: 4,
  [TableType.fgohn_object]: 5,
  [TableType.fgotn_object]: 6,
  [TableType.fglesn_object]: 7,
  [TableType.fgan_object]: 8,
  [TableType.fgahn_object]: 8,

  [TableType.ancr_object]: null,
  [TableType.eroi_object]: null,
  [TableType.recycler_inclusion]: null,
  [TableType.recycler_actualization]: null,
  [TableType.public_inspector_request]: null,
  [TableType.atex_object]: null,
  [TableType.fkko_object]: null,
  [TableType.animals_object]: null,
  [TableType.animal_registry]: null,
  [TableType.knm_object]: null,
  [TableType.fgn_category_change]: null,

  [TableType.knd_quotas_card]: null,
  [TableType.knd_quotas_program]: null,

  [TableType.pm_visit_card]: null,
  [TableType.pm_warning_card]: null,

  [TableType.registry_permission]: null,
  [TableType.commission_object]: null,
  [TableType.liquidated_object]: null,
  [TableType.cites_object]: null,

  [TableType.addcharge]: null,
  [TableType.appointment]: null,

  [TableType.dpnvos_requests]: null,
  [TableType.dpnvos_clarification]: null,
  [TableType.dpnvos_actual_expenses]: null,
  [TableType.dpnvos_control_act]: null,
  [TableType.ecocharge_registry]: null,
  [TableType.utilization_registry]: null,
  [TableType.ecocharge_control_act]: null,
  [TableType.utilization_control_act]: null,
  [TableType.rosnedra_notice]: null,
  [TableType.expertise_request]: null,
  [TableType.expense_reimbursement]: null,
  [TableType.termination_request]: null,
  [TableType.rosnedra_notice]: null,
  [TableType.ecocharge_request]: null,
  [TableType.utilization_request]: null,

  [TableType.control_objects_by_indicator]: null,
  [TableType.expertise_request_clati]: null,
  [TableType.key_metric]: null,
  [TableType.pre_trial_appeal]: null,

  [TableType.administrative_matter]: null,
  [TableType.administrative_matter_order]: null,
  [TableType.matters_submission]: null,
};

export const RegistryExportCode: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: 'knm_reports_test',

  [TableType.fgen_object]: 'reestr_fgen_upload',
  [TableType.fggn_object]: 'reestr_fggn_upload',
  [TableType.fgzn_object]: 'reestr_fgzn_upload',
  [TableType.fgohn_object]: 'reestr_fgohn_upload',
  [TableType.fgotn_object]: 'reestr_fgotn_upload',
  [TableType.fglesn_object]: 'reestr_fglesn_upload',
  [TableType.fgan_object]: 'reestr_fgan_upload',
  [TableType.fgahn_object]: 'reestr_fgahn_upload',

  [TableType.ancr_object]: '',
  [TableType.eroi_object]: 'reestr_eroi_upload',
  [TableType.recycler_inclusion]: '',
  [TableType.recycler_actualization]: '',
  [TableType.public_inspector_request]: '',
  [TableType.atex_object]: 'reestr_atex_upload',
  [TableType.fkko_object]: 'reestr_FKKO_upload',
  [TableType.animals_object]: 'reestr_animals_upload',
  [TableType.animal_registry]: 'reestr_animals_upload',
  [TableType.knm_object]: '',
  [TableType.fgn_category_change]: '',

  [TableType.knd_quotas_card]: '',
  [TableType.knd_quotas_program]: 'knd_quotas_program',

  [TableType.pm_visit_card]: '',
  [TableType.pm_warning_card]: '',

  [TableType.registry_permission]: '',
  [TableType.commission_object]: '',
  [TableType.liquidated_object]: '',
  [TableType.cites_object]: '',

  [TableType.addcharge]: '',
  [TableType.appointment]: '',

  [TableType.dpnvos_requests]: 'dpnvos_registry_report',
  [TableType.dpnvos_clarification]: 'dpnvos_clarification',
  [TableType.dpnvos_actual_expenses]: 'dpnvos_expenses',
  [TableType.dpnvos_control_act]: 'dpnvos_control_act',
  [TableType.ecocharge_registry]: '',
  [TableType.utilization_registry]: '',
  [TableType.ecocharge_control_act]: '',
  [TableType.utilization_control_act]: '',
  [TableType.expertise_request]: '',
  [TableType.expense_reimbursement]: '',
  [TableType.termination_request]: '',
  [TableType.rosnedra_notice]: 'reestr_rosnedr',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: '',
  [TableType.expertise_request_clati]: '',
  [TableType.key_metric]: '',
  [TableType.pre_trial_appeal]: '',

  [TableType.administrative_matter]: '',
  [TableType.administrative_matter_order]: '',
  [TableType.matters_submission]: '',
};

export const OpenTypeMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: 'reestr_recult_list',

  [TableType.fgen_object]: 'reestr_fgen_list',
  [TableType.fggn_object]: 'reestr_fggn_list',
  [TableType.fgzn_object]: 'reestr_fgzn_list',
  [TableType.fgohn_object]: 'reestr_fgohn_list',
  [TableType.fgotn_object]: 'reestr_fgotn_list',
  [TableType.fglesn_object]: 'reestr_fglesn_list',
  [TableType.fgan_object]: 'reestr_fgan_list',
  [TableType.fgahn_object]: 'reestr_fgahn_list',
  [TableType.ancr_object]: 'reestr_ancr_list',

  [TableType.eroi_object]: 'reestr_eroi_list',
  [TableType.recycler_inclusion]: '',
  [TableType.recycler_actualization]: '',
  [TableType.public_inspector_request]: '',
  [TableType.atex_object]: 'reestr_atex_list',
  [TableType.fkko_object]: 'reestr_fkko_list',
  [TableType.animals_object]: 'reestr_animals_list',
  [TableType.animal_registry]: '',
  [TableType.knm_object]: 'reestr_knm_list',
  [TableType.fgn_category_change]: 'reestr_knm_list',

  [TableType.knd_quotas_card]: '',
  [TableType.knd_quotas_program]: '',

  [TableType.pm_visit_card]: '',
  [TableType.pm_warning_card]: '',

  [TableType.registry_permission]: '',
  [TableType.commission_object]: '',
  [TableType.liquidated_object]: 'reestr_liquidated_subsoil_objects_list',
  [TableType.cites_object]: 'cites_list',

  [TableType.addcharge]: '',
  [TableType.appointment]: '',

  [TableType.dpnvos_requests]: '',
  [TableType.dpnvos_clarification]: '',
  [TableType.dpnvos_actual_expenses]: '',
  [TableType.dpnvos_control_act]: '',
  [TableType.ecocharge_registry]: '',
  [TableType.utilization_registry]: '',
  [TableType.ecocharge_control_act]: '',
  [TableType.utilization_control_act]: '',
  [TableType.expertise_request]: '',
  [TableType.expense_reimbursement]: '',
  [TableType.termination_request]: '',
  [TableType.rosnedra_notice]: '',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: '',
  [TableType.expertise_request_clati]: '',
  [TableType.key_metric]: '',
  [TableType.pre_trial_appeal]: '',

  [TableType.administrative_matter]: '',
  [TableType.administrative_matter_order]: '',
  [TableType.matters_submission]: '',
};

export const DetailsRouteMap: { [key in ReestrTableType]: string } = {
  [TableType.fgen_recult]: '',

  [TableType.fgen_object]: '/rpn/fgen-object/details/fgen_object',
  [TableType.fggn_object]: '/rpn/fggn-object/details/fggn_object',
  [TableType.fgzn_object]: '/rpn/fgzn-object/details/fgzn_object',
  [TableType.fgohn_object]: '/rpn/fgohn_object/details/fgohn-object',
  [TableType.fgotn_object]: '/rpn/fgotn-object/details/fgotn_object',
  [TableType.fglesn_object]: '/rpn/fglesn-object/details/fglesn_object',
  [TableType.fgan_object]: '/rpn/fgan-object/details/fgan_object',
  [TableType.fgahn_object]: '/rpn/fgahn-object/details/fgahn_object',
  [TableType.ancr_object]: '',

  [TableType.eroi_object]: '',
  [TableType.recycler_inclusion]: '',
  [TableType.recycler_actualization]: '',
  [TableType.public_inspector_request]: '',
  [TableType.atex_object]: '',
  [TableType.fkko_object]: '',
  [TableType.animals_object]: '',
  [TableType.animal_registry]: '',
  [TableType.knm_object]: '',

  [TableType.knd_quotas_card]: '',
  [TableType.knd_quotas_program]: '',

  [TableType.pm_visit_card]: '',
  [TableType.pm_warning_card]: '',

  [TableType.registry_permission]: '',
  [TableType.commission_object]: '',
  [TableType.liquidated_object]: '',
  [TableType.cites_object]: '',

  [TableType.addcharge]: '',

  [TableType.dpnvos_requests]: '',
  [TableType.dpnvos_clarification]: '',
  [TableType.dpnvos_actual_expenses]: '',
  [TableType.dpnvos_control_act]: '',
  [TableType.ecocharge_registry]: '',
  [TableType.utilization_registry]: '',
  [TableType.ecocharge_control_act]: '',
  [TableType.utilization_control_act]: '',
  [TableType.expertise_request]: '',
  [TableType.expense_reimbursement]: '',
  [TableType.termination_request]: '',
  [TableType.rosnedra_notice]: '',
  [TableType.ecocharge_request]: '',
  [TableType.utilization_request]: '',

  [TableType.control_objects_by_indicator]: '/rpn/knd/control-objects-by-indicators/details/',
  [TableType.expertise_request_clati]: '/rpn/atex/expertise-request-clati/details/',
  [TableType.key_metric]: '/rpn/knd/key-metric/details/',
  [TableType.pre_trial_appeal]: '/rpn/knd/pre-trial-appeal/view/',

  [TableType.administrative_matter]: '/rpn/knd/administrative-matter/details/',
  [TableType.administrative_matter_order]: '/rpn/knd/administrative-matters-order/details/',
  [TableType.matters_submission]: '/rpn/knd/administrative-matters-submission/details/',
  [ReestrTableType.appointment]: '',
  [ReestrTableType.fgn_category_change]: '',
  [TableType.pm_warning_card]: '/rpn/knd/pm/warning-card/details/',
  [TableType.pm_visit_card]: '/rpn/knd/pm/visit-card/details/',
};

export const ObjectTypeByStateServiceIdMap = Object.keys(StateServiceIdMap).reduce(
  (acc, key) => {
    acc[StateServiceIdMap[key]] = ReestrTableType[key];
    return acc;
  },
  {} as { [key: number]: ReestrTableType },
);

export const ReestrTableTypeStringByStateServiceIdMap = Object.keys(TableTypeMap).reduce(
  (acc, key) => {
    acc[StateServiceIdMap[TableTypeMap[key]]] = key;
    return acc;
  },
  {} as { [key in ReestrTableType]: ReestrTableTypeString },
);
